import dayjs from "dayjs";
import { db, firestore } from "../app/firebase";
import { removeUndefined3 } from "./helpers";

export async function writeHistoryText(param) {
    const { text, type, action, location, mod_uid, user_info, automatic } = param;
    try {
        // console.log("this is text", text);
        let user_info_definded = removeUndefined3(user_info);
        let data = {
            text,
            type,
            action,
            location,
            date: firestore.FieldValue.serverTimestamp(),
            mod_uid,
            user_info: {...user_info_definded},
            automatic
        }
        
        let cleanedData = removeUndefined3(data);
        console.log("Cleaned data after undefined", cleanedData);
        // return 
        let res = await db.collection("history_text")
            .doc()
            .set(cleanedData,
                { merge: true }
            )
        // console.log(res);
    } catch (error) {
        console.error(error);
    }

}

export const ADMIN_UID_TO_NAME = {
    "ppSfJg7w0cP97RDIKkCVv2uqnZT2": "Andrea",
    "ycHdPmvT4IRYLseSyDYwd3yl8jP2": "Lama",
    "jvuqRJ6F1iX5mKvtsXIDDUUguFr2": "Nazek",
    "1Jfo0I33czTkXl8JHVzlhf13ELG2": "Rashel",
    "njuuPRgV9EeB21ShGHBjFCBB0YB2": "Sara T.",
    "0VGc21UpTBTssL72Xvv2EqK7rhz2": "Remy",
    "8OaqIpEQQDduH4TAdnqJKuEHZUG2": "Gina",
    "TV6vQjasQ2NEAFt79YpKcoVgbD93": "Manal",
    "Ku18iqebeSgFhOjkKdePV8Rb3ew1": "Muhanad",
    "OcO8YQZDEZRmI6wf7tS9GveGpkq2": "Sara Y.",
    "OQ3qTCRQODZJ78Em4sXuHS6t6NA2": "Aya",
    "BuT86HiYu1b14bmbffvLv5w7D362": "Layale",
    "8InOiDv72waRp4Nul3aFddOS1ng2": "Clement",
    "1bm4BQiW86cz96RsEJkECHZW89E2": "Shogheir",
    "pZn6aKD7GDUVKD1JDJsJW474Azp2": "Aboudi (DEV)",
    "42qwRbaoxdXad83huD6h6h6wAi83": "Moon",
    "7KBSvNg5HuOfwY3BmUZS4WcJV2e2": "Mars",
    "cRaB1a4XnnODOdyybtLuFicrLv93": "Jupiter",
    "od2wrmeuAvUPeip05wS1wsD2D3s1": "Saturn"
}

export const fetchTotalProfileCount = async() => {
    try {
        const res = await db.collection("chat_report").doc("info").get();
        console.log("Profile info res", res.data());
        return [dayjs.unix(res.data()?.last_mod_date?.seconds).fromNow(), res.data()?.last_mod_uid]
    } catch (err) {
        console.log(err);
        return false;
    }
}
