import { useEffect, useMemo } from "react";
import "./body.css";
import ChatCard from "./card/ChatCard";
import {
  selectActivePost,
  selectLoading,
  selectPage,
  selectPosts,
  setActivePost,
} from "../../chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import useChats from "../../useChats";
import { previousPage } from "../../utils";

export default function Body() {
  let posts = useSelector(selectPosts);
  let page = useSelector(selectPage);
  let loading = useSelector(selectLoading);
  const activePost = useSelector(selectActivePost);
  const dispatch = useDispatch();
  const { fetchNextChats } = useChats();

  useEffect(() => {
    const handleKeyUp = (event) => {
      event.preventDefault();
      if (activePost === -1) {
        console.log("Inside keyup useEffect");
        if (event.code === "Space") {
          dispatch(setActivePost(0));
        }
        if (event.code === "ArrowLeft") {
          previousPage(page);
        } else if (event.code === "ArrowRight") {
          fetchNextChats();
        }
      }
    };

    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [dispatch, fetchNextChats, previousPage, activePost, page]);

  const renderChats = useMemo(() => {
    if (!posts[page] || posts[page].length === 0) {
      return <div>No chats available for this page.</div>;
    }

    return posts[page].map((chat, idx) => (
      <div className="card-wrapper" key={chat.uid}>
        <ChatCard
          key={chat.uid}
          open_report_counter={chat.open_report_counter}
          uid={chat.uid || chat.reportedUserID}
          idx={idx}
          is_moderated={chat.isModerated || false}
          is_processing_moderation={chat.isProcessingModeration || false}
        />
      </div>
    ));
  }, [posts, page]);

  return (
    <div className="body-container">
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ProgressSpinner />
        </div>
      ) : (
        renderChats
      )}
    </div>
  );
}
