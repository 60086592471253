import dayjs from "dayjs";
import {
  age_verif_mod,
  ban_user_mod,
  chat_report_mod,
  db,
  firestore,
  logAnalytics,
  view_verif_img,
} from "../../app/firebase";
import store from "../../app/store";
import { keepHistory } from "../chat/functions/Keep.functions";
import { keepNotification } from "../chat/model";
import { changePage } from "./chatSlice";
import { ADMIN_UID_TO_NAME } from "../../helpers/utils";

export const previousPage = (page) => {
  if (page > 1) store.dispatch(changePage(page - 1));
};

export const deepMerge = (target, source) => {
  Object.keys(source).forEach((key) => {
    if (Array.isArray(source[key])) {
      // If the source property is an array, overwrite the target's value entirely.
      target[key] = source[key];
    } else if (
      source[key] instanceof Object &&
      key in target &&
      target[key] instanceof Object
    ) {
      // If both the source and target properties are objects, merge them recursively.
      deepMerge(target[key], source[key]);
    } else {
      // Otherwise, assign the source property directly.
      target[key] = source[key];
    }
  });
};


export const preFetchImages = async (images) => {
  // Helper function to validate URL
  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
      return true;
    } catch (error) {
      return false;
    }
  };

  const loadImage = (src) =>
    new Promise((resolve) => {
      console.log("loading image:", src);

      // Check if the src is a valid URL
      if (!isValidUrl(src)) {
        console.error(`Invalid URL: ${src}`);
        return resolve(null);
      }

      const img = new Image();
      img.src = src;
      img.onload = () => {
        console.log("Image loaded:", src);
        resolve(src);
      };
      img.onerror = () => {
        console.error(`Failed to load image: ${src}`);
        resolve(null);
      };
    });

  if (!images || images.length === 0) {
    console.log("No images to pre-fetch");
    return [];
  }

  const loadedImages = await Promise.all(
    images.map((src) => {
      if (!src) {
        console.error("Image source is empty or falsy");
        return Promise.resolve(null);
      }
      return loadImage(src);
    })
  );

  // Filter out any null values (failed or invalid images)
  return loadedImages.filter((src) => src !== null);
};



export async function fetchAdditionalData(userID, index = 0) {
  try {
    let start_time = Date.now();
    const getRoomIdData = await db
      .collection("chat_report")
      .doc(userID)
      .collection("report_list")
      .where("need_moderation", "==", true)
      .orderBy("date", "desc")
      .limit(20)
      .get();

    console.log(
      "finished fetching more data in " + (Date.now() - start_time) + "ms"
    );
    let posts = [];
    getRoomIdData?.forEach((item, i) => {
      let post = { ...item.data(), id: item.id };
      posts.push(post);
    });
    const chatPostsPromise = getChatData(posts[index]?.roomID);
    const reportedUserDataPromise = getReportedUser(posts[index]?.uid_reported);
    const reportingUserDataPromise = getReportingUser(
      posts[index]?.uid_reporting
    );
    const openCasePostsPromise = getOpenCaseUser(posts[index]?.uid_reported);
    const closeCasePostsPromise = getCloseCaseUser(posts[index]?.uid_reported);

    const [
      chatPosts,
      reportedUserData,
      reportingUserData,
      openCasePosts,
      closeCasePosts,
    ] = await Promise.all([
      chatPostsPromise,
      reportedUserDataPromise,
      reportingUserDataPromise,
      openCasePostsPromise,
      closeCasePostsPromise,
    ]);

    const [images, ipThreat] = await Promise.all([
      fetchImages(posts[index]?.uid_reported, posts[index]?.uid_reporting),
      getIpThreat(posts[index]?.uid_reported),
    ]);

    let additionalData = {
      roomID: posts[index]?.roomID,
      uid_reported: posts[index]?.uid_reported,
      uid_reporting: posts[index]?.uid_reporting,
      reason: posts[index]?.reason,
      chat: chatPosts?.chatPosts?.reverse(),
      chatLastSeen: chatPosts?.lastSeen,
      // -----------------------------------------
      reportedUsername: reportedUserData?.username,
      reportedUserID: reportedUserData?.userID,
      reportedProfileImage: reportedUserData?.first_profile_url,
      reportedVerifyImage: images?.reported,
      reportedGender: reportedUserData?.gender,
      reportedAge: reportedUserData?.age,
      reportedCountry: reportedUserData?.country,
      reportedDate: reportedUserData?.dateKeys,
      // -----------------------------------------
      reportingUsername: reportingUserData?.username,
      reportingUserID: reportingUserData?.userID,
      reportingProfileImage: reportingUserData?.first_profile_url,
      reportingVerifyImage: images?.reporting,
      reportingGender: reportingUserData?.gender,
      reportingAge: reportingUserData?.age,
      reportingCountry: reportingUserData?.country,
      reportingDate: reportingUserData?.dateKeys,
      // ----------------------------------------------
      openCase: openCasePosts,
      // ----------------------------------------------
      closeCase: closeCasePosts,
      // ----------------------------------------------
      ipThreat: ipThreat,
    };

    return additionalData;
  } catch (error) {
    console.log(error, "Error in Database Query");
    return {};
  }
}

export async function fetchAdditionalDataFromOpenCase(opencases, index = 0) {
  try {
    const chatPostsPromise = getChatData(opencases[index]?.roomID);
    const reportedUserDataPromise = getReportedUser(opencases[index]?.uid_reported);
    const reportingUserDataPromise = getReportingUser(
      opencases[index]?.uid_reporting
    );

    const [
      chatPosts,
      reportedUserData,
      reportingUserData
    ] = await Promise.all([
      chatPostsPromise,
      reportedUserDataPromise,
      reportingUserDataPromise
    ]);

    const [images, ipThreat] = await Promise.all([
      fetchImages(opencases[index]?.uid_reported, opencases[index]?.uid_reporting),
      getIpThreat(opencases[index]?.uid_reported),
    ]);

    let additionalData = {
      roomID: opencases[index]?.roomID,
      uid_reported: opencases[index]?.uid_reported,
      uid_reporting: opencases[index]?.uid_reporting,
      reason: opencases[index]?.reason,
      chat: chatPosts?.chatPosts?.reverse(),
      chatLastSeen: chatPosts?.lastSeen,
      // -----------------------------------------
      reportedUsername: reportedUserData?.username,
      reportedUserID: reportedUserData?.userID,
      reportedProfileImage: reportedUserData?.first_profile_url,
      reportedVerifyImage: images?.reported,
      reportedGender: reportedUserData?.gender,
      reportedAge: reportedUserData?.age,
      reportedCountry: reportedUserData?.country,
      reportedDate: reportedUserData?.dateKeys,
      // -----------------------------------------
      reportingUsername: reportingUserData?.username,
      reportingUserID: reportingUserData?.userID,
      reportingProfileImage: reportingUserData?.first_profile_url,
      reportingVerifyImage: images?.reporting,
      reportingGender: reportingUserData?.gender,
      reportingAge: reportingUserData?.age,
      reportingCountry: reportingUserData?.country,
      reportingDate: reportingUserData?.dateKeys,
      ipThreat: ipThreat
    };
    console.log("fetched additional data using open case", additionalData);
    return additionalData;
  } catch (error) {
    console.log(error, "Error in Database Query");
    return {};
  }
}

const fetchImages = async (reported, reporting) => {
  try {
    let start_time = Date.now();
    const [reportedVerifImage, reportingVerifImage] = await Promise.all([
      view_verif_img({ uid: reported }),
      view_verif_img({ uid: reporting }),
    ]);
    console.log(
      "finished fetching images in " + (new Date() - start_time) + "ms"
    );
    return {
      reported: reportedVerifImage?.data?.url,
      reporting: reportingVerifImage?.data?.url,
    };
  } catch (error) {
    console.log(error, "error");
  }
};

const getIpThreat = async (reported) => {
  try {
    const banDoc = await db.collection("banned_moderation").doc(reported).get();
    const banData = banDoc?.data();

    if (!banData) {
      return "";
    }

    if (banData.banned_ip_country === true) {
      return "🔴 Banned Country";
    }

    return banData.ip_threat;
  } catch (error) {
    console.error("Error loading banned info:", error);
    return "";
  }
};

async function getChatData(roomId) {
  let chatPosts;
  try {
    let chatData = await db
      .collection("chat")
      .doc(roomId)
      .collection("discussion")
      .orderBy("date", "desc")
      .limit(21)
      .get();
    let chatPosts = [];
    let lastSeenMessage = null;
    chatData.forEach((item, i) => {
      let post = { ...item.data(), id: item.id };
      chatPosts.push(post);
    });
    if (chatPosts.length > 0) {
      lastSeenMessage = chatPosts[chatPosts.length - 1];
    }
    return { chatPosts: chatPosts, lastSeen: lastSeenMessage };
  } catch (error) {
    return (chatPosts = []);
  }
}

async function getReportedUser(uid_reported) {
  let reportedUserData;
  try {
    let reportedUser = await db.collection("users").doc(uid_reported).get();
    reportedUserData = reportedUser.data();
    return reportedUserData;
  } catch (error) {
    return (reportedUserData = {});
  }
}

async function getReportingUser(uid_reporting) {
  let reportingUserData;
  try {
    let reportingUser = await db.collection("users").doc(uid_reporting).get();

    reportingUserData = reportingUser.data();
    return reportingUserData;
  } catch (error) {
    return (reportingUserData = {});
  }
}

async function getOpenCaseUser(uid_reported) {
  let openCasePosts = [];
  try {
    let openCases = await db
      .collection("chat_report")
      .doc(uid_reported)
      .collection("report_list")
      .where("need_moderation", "==", true)
      .orderBy("date", "desc")
      .limit(30)
      .get();

    openCases.forEach((item, i) => {
      let post = { ...item.data(), id: item.id };
      openCasePosts.push(post);
    });
    return openCasePosts;
  } catch (error) {
    return (openCasePosts = []);
  }
}

async function getCloseCaseUser(uid_reported) {
  let closeCasePosts = [];
  try {
    let closeCases = await db
      .collection("chat_report")
      .doc(uid_reported)
      .collection("report_list")
      .where("need_moderation", "==", false)
      .orderBy("date", "desc")
      .limit(30)
      .get();

    closeCases.forEach((item, i) => {
      let post = { ...item.data(), id: item.id };
      closeCasePosts.push(post);
    });

    return closeCasePosts;
  } catch (error) {
    return (closeCasePosts = []);
  }
}

export const handleModerationAPI = async (action, data, mod_uid) => {
  let success = false;
  switch (action) {
    case "keep":
      success = await keepPost(data, mod_uid);
      break;
    case "notify":
      success = await notifyPost(data);
      break;
    case "ban":
      success = await displayBan(data);
      break;
    case "selling":
      const [call_1, call_2] = await Promise.all([
        notifyPost(data),
        sellingPost(data),
      ]);
      success = call_1 && call_2;
      break;
    case "age":
      success = await handleAge(data);
      break;
    default:
      break;
  }
  return success;
};

async function keepPost(data, mod_uid) {
  try {
    const res = await chat_report_mod({
      reportedID: data?.uid_reported,
      roomID: data?.roomID,
      mod_res: 1,
      mod_age_req: data.isAgeModerated || null,
    });
    console.log("Result", res);
    if (res?.data?.status !== 1) {
      return false;
    }
    const postID = data?.id;
    const isActivePostBoosted = data?.boost_request;

    await Promise.all([
      adminAction(mod_uid),
      // keepHistory({ post: data, user: mod_uid, location: "all_posts" }),
      keepNotification({ fcm_token: data.fcm_token }),
      db
        .collection("banned_moderation")
        .doc(postID)
        .set({ last_post_reported: false }, { merge: true }),
      db
        .collection("public_post")
        .doc(postID)
        .set(
          {
            finish_processing: true,
            img_state: 5,
            post_seen_mod: true,
            datePost: firestore.FieldValue.serverTimestamp(),
            post_seen_by: firestore.FieldValue.arrayUnion(mod_uid.uid),
            seen_mod_date: firestore.FieldValue.serverTimestamp(),
            ...(isActivePostBoosted ? { boost_safe: true } : {}),
          },
          { merge: true }
        )
        .then(() => log("keep_img_mod", data)),
      db
        .collection("moderation_post")
        .doc(postID)
        .set(
          {
            moderated: true,
            post_seen_mod: true,
            post_seen_by: firestore.FieldValue.arrayUnion(mod_uid.uid),
            seen_mod_date: firestore.FieldValue.serverTimestamp(),
            last_post_reported: false,
          },
          { merge: true }
        ),
    ]);
    console.log("All keepPost operations completed successfully");
    return true;
  } catch (error) {
    console.error("Error in keepPost:", error);
    return false;
  }
}

export async function notifyPost(data) {
  try {
    await chat_report_mod({
      reportedID: data?.uid_reported,
      roomID: data?.roomID,
      mod_res: 2,
      mod_age_req: data.isAgeModerated || null,
    });
    return true;
  } catch (error) {
    console.error("Error in notifyPost:", error);
    return false;
  }
}

const displayBan = async (post_data) => {
  try {
    const data = {
      reportedID: post_data?.uid_reported,
      roomID: post_data?.roomID,
      mod_res: 3,
      reason: post_data?.reason || "No reason because of a bug",
    };
    await chat_report_mod(data);
    return true;
  } catch (error) {
    console.error("Error in displayBan:", error);
    return false;
  }
};

const sellingPost = async (data) => {
  try {
    const res = await ban_user_mod({
      uid: data?.reportedUserID,
      action: "ban",
      reason: "selling",
      location: "chat",
      roomID: data?.roomID,
    });

    console.log("selling data:", data);

    if (res.data.status !== 1) {
      alert(`problem deleting: ${res.data.message}`);
      return false;
    }

    return true;
  } catch (err) {
    alert(`problem deleting for: ${err}`);
    console.error("error with ban_user_mod:", err);
    return false;
  }
};

export const handleAge = async (data) => {
  try {
    // send to age verif, this won't move to the next post
    const res = await age_verif_mod({
      req_age: true,
      uid: data?.reportedUserID,
    });

    if (res?.data?.status !== 1) {
      console.error("age_verif_mod error with message:", res?.data?.message);
      console.error("age_verif_mod error with status:", res?.data?.status);
      alert(`age_verif_mod error: ${res?.data?.message}`);
      return false;
    }

    return true;
  } catch (err) {
    alert(`age_verif_mod error: ${err}`);
    console.error("age_verif_mod", err);
    return false;
  }
};

async function adminAction(mod_uid) {
  try {
    const actionObject = {
      last_action: Date.now(),
      userID: mod_uid.uid,
      last_action_timestamp: firestore.FieldValue.serverTimestamp(),
    };
    await db.collection("admin").doc("info").set(actionObject, { merge: true });
    return true;
  } catch (error) {
    console.error("Error in adminAction:", error);
    return false;
  }
}

function log(event, data, reason = null) {
  const logObject = {
    event_type: event,
    email: "email@gmail.com",
    userID: data?.userID,
    mod_location: "all_posts",
    imageUrlPost: data?.imageUrlPost,
    postText: data?.postText,

    data: {
      reason,
      ...(data?.response_time &&
        (event === "skip_post" ||
          event === "keep_img_mod" ||
          event === "remove_img_mod")
        ? {
          response_time: dayjs().unix() - data?.response_time,
        }
        : {}),
    },
  };

  logAnalytics(logObject).catch((err) => console.error("log err", err));
}

export const checkOpenCasesAPI = async (uid_reported) => {
  try {
    let openCases = await db
      .collection("chat_report")
      .doc(uid_reported)
      .collection("report_list")
      .where("need_moderation", "==", true)
      .orderBy("date", "desc")
      .limit(30)
      .get();
    console.log("openCases", openCases.size);
    return openCases?.size > 1;
  } catch (error) {
    return [];
  }
};

export const mapHistoryPostToCommonStructure = (historyPost) => {
  const reportedUser = historyPost.reportedUser || {};
  const reportingUser = historyPost.reportingUser || {};

  return {
    // Reported User Details
    reportedUsername: reportedUser.username || "",
    reportedUserID: reportedUser.userID || "",
    reportedProfileImage: reportedUser.first_profile_url || "",
    reportedVerifyImage: reportedUser.verifImgUrl
      ? reportedUser.verifImgUrl
      : "",
    reportedAge: reportedUser.age || "",
    reportedCountry: reportedUser.country || "",
    reportedGender: reportedUser.gender === 1 ? "Female" : "Male",

    // Reporting User Details
    reportingUsername: reportingUser.username || "",
    reportingUserID: reportingUser.userID || "",
    reportingProfileImage: reportingUser.first_profile_url || "",
    reportingVerifyImage: reportingUser.verifImgUrl
      ? reportingUser.verifImgUrl
      : "",
    reportingAge: reportingUser.age || "",
    reportingGender: reportingUser.gender === 0 ? "Male" : "Female",
    reportingCountry: reportingUser.country || "",

    // Case Details
    reason: historyPost.reason || "",
    mod_comment: historyPost.mod_comment || "",
    mod_res: historyPost.mod_res || 0,
    mod_uid: historyPost.mod_uid || "",
    mod_reason: historyPost.mod_reason,

    // Arrays that might need transformation
    chat: historyPost.messages || [],
    openCase: [], // You'll need to transform these based on your history data structure
    closeCase: [], // You'll need to transform these based on your history data structure

    // Other fields with defaults
    last_report_date: historyPost?.date?.seconds || "",
    total_report_counter: historyPost.post_counter || 0,
    last_mod_date: historyPost.date_expiration?.seconds || "",
    last_mod_uid: historyPost.mod_uid || "",
    open_report_counter: 0, // Set based on your needs
    warn_counter_thirty_days: 0, // Set based on your needs
    warn_counter: 0, // Set based on your needs
    date_thirty_days: "", // Set based on your needs
    id: historyPost.id || "",
    roomID: "", // Set if available in history
    ipThreat: "", // Set if available in history
    isHistory: true,

    // Optional: Add any additional fields that your component might need
  };
};

export async function loadAdminsAPI(uid) {
  const adminsArray = [];
  try {
    const snapshot = await db
      .collection("admin")
      .where("admin", "==", true)
      .get();
    snapshot.forEach((admin) => {
      const { userID } = admin.data();
      adminsArray.push({
        label: ADMIN_UID_TO_NAME[userID] || userID,
        value: userID,
      });
    });
    adminsArray.sort((a, b) => a.label.localeCompare(b.label));
    if (uid) {
      const currentAdminIndex = adminsArray.findIndex(
        (admin) => admin.value === uid
      );
      if (currentAdminIndex !== -1) {
        const [currentAdminEntry] = adminsArray.splice(currentAdminIndex, 1);
        adminsArray.unshift(currentAdminEntry);
      }
    }
    return adminsArray;
  } catch (error) {
    console.error("Error loading admins:", error);
    return [];
  }
}

export const hasLotOfUndefinedValues = (data) => {
  const undefinedCount = Object.values(data).filter(
    (value) => value === undefined
  ).length;
  if (undefinedCount > 10)
    console.log(
      "additionalData has this much undefined values:",
      undefinedCount
    );
  return undefinedCount > 10;
};
