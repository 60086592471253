import {
  FaBan,
  FaExternalLinkAlt,
  FaInstagram,
  FaSnapchatGhost,
  FaTiktok,
  FaTwitter,
  FaAngleDoubleRight,
} from "react-icons/fa";

import {
  RiArrowGoBackLine,
  RiArrowRightLine,
  RiCheckDoubleFill,
  RiDeleteBin2Line,
} from "react-icons/ri";
import { ControlButton as Control } from "../components/ControlButton";
import { deletePostText } from "./functions/deletePostText";
import { MdArrowBack } from "react-icons/md";
import "./posts.style.scss";

interface Props {
  activePost: any;
  posts: any;
  reasonPrompt: any;
  banPrompt: any;
  textPrompt: any;
  activeKey: any;
  deleteForUnderage: any;
  deleteforSexual: any;
  deleteForNudity: any;
  deleteForSelling: any;
  keepPost: any;
  displayBan: any;
  setReasonPrompt: any;
  setTextPrompt: any;
  deleteBio: any;
  deleteUsername: any;
  deletePostTextFunction: any;
  verifyProfile: any;
  deleteVerificationImg: any;
  deletePost: any;
  ban: any;
  hide: any;
  supreme: any;
  setBanPrompt: any;
  reported: boolean;
  unread: boolean;
  userID: any;
  userName: any;
  postText: any;
  location: any;
  handleSetCopy: any;
  id: any;
  updateprifilepic: any;
  newbioapi: any;
  showLinksButton: any;
  openLink: any;
  openLinks: any;
  showLinks: any;
  setShowLinks: any;
  links: any;
}

export function Controls({
  activePost,
  posts,
  reasonPrompt,
  banPrompt,
  textPrompt,
  activeKey,
  deleteForUnderage,
  deleteforSexual,
  deleteForNudity,
  deleteForSelling,
  keepPost,
  displayBan,
  setReasonPrompt,
  setTextPrompt,
  setBanPrompt,
  deleteBio,
  deleteUsername,
  deletePostTextFunction,
  verifyProfile,
  deleteVerificationImg,
  deletePost,
  ban,
  hide,
  supreme,
  reported,
  unread,
  userID,
  userName,
  postText,
  location,
  handleSetCopy,
  id,
  updateprifilepic,
  newbioapi,
  showLinksButton = false,
  openLink,
  openLinks,
  showLinks,
  setShowLinks,
  links,
}: Props): JSX.Element {
  return (
    <>
      <div
        className={
          activePost === -1 ||
          activePost >= posts.length ||
          reasonPrompt ||
          banPrompt ||
          textPrompt
            ? "controls hidden"
            : "controls"
        }
      >
        {!showLinks && (
          <>
            <Control
              letter="v"
              activeKey={activeKey}
              description="Verify"
              icon={<RiArrowRightLine />}
              onClick={verifyProfile}
            />

            <Control
              letter="a"
              activeKey={activeKey}
              description="Underage"
              icon={<FaBan />}
              onClick={deleteForUnderage}
            />
            <Control
              letter="s"
              activeKey={activeKey}
              description="Sexual"
              icon={<FaBan />}
              onClick={deleteforSexual}
            />
            <Control
              letter="d"
              activeKey={activeKey}
              description="Nudity"
              icon={<FaBan />}
              onClick={deleteForNudity}
            />
            <Control
              letter="z"
              activeKey={activeKey}
              description="Selling"
              icon={<FaBan />}
              onClick={deleteForSelling}
            />
            <Control
              letter="x"
              activeKey={activeKey}
              description="Guidelines"
              icon={<FaBan />}
              onClick={() =>
                deletePost({
                  reason_report_int: 0,
                  reason_report: "Against guidelines",
                })
              }
            />
            <Control
              letter="f"
              activeKey={activeKey}
              description="Delete"
              onClick={() => {
                setReasonPrompt(true);
              }}
              icon={<RiDeleteBin2Line />}
            />
            <Control
              letter="j"
              activeKey={activeKey}
              onClick={keepPost}
              description="Keep"
              icon={<RiCheckDoubleFill />}
            />
            <Control
              letter="k"
              onClick={displayBan}
              activeKey={activeKey}
              description="Ban"
              icon={<FaBan />}
            />
            {/* <Control
          letter="c"
          activeKey={activeKey}
          description="Caption"
          icon={<RiArrowRightLine />}
          onClick={deletePostTextFunction}
          /> */}
            <Control
              letter="i"
              activeKey={activeKey}
              description="Profile"
              icon={<RiArrowRightLine />}
              onClick={() => setTextPrompt(true)}
            />

            <Control
              letter="l"
              activeKey={activeKey}
              className="profile-btn-show"
              style={showLinksButton ? {} : { opacity: 0.3 }}
              description="Links"
              // onClick={openLinks}
              onClick={() => {
                if (showLinksButton) setShowLinks(true);
              }}
              icon={<FaExternalLinkAlt />}
            />
          </>
        )}
        {showLinks && (
          <>
            <Control
              letter="A"
              activeKey={activeKey}
              className="profile-btn-show"
              description="Back"
              onClick={() => setShowLinks(false)}
              icon={<MdArrowBack />}
            />
            {links[0] && (
              <Control
                letter="1"
                activeKey={activeKey}
                className="profile-btn-show"
                description="Snapchat"
                // onClick={openLink(links[0])}
                onClick={() => {
                  if (links[0])
                    openLink(`https://www.snapchat.com/add/${links[0]}`);
                }}
                icon={<FaSnapchatGhost />}
              />
            )}
            {links[1] && (
              <Control
                letter="2"
                activeKey={activeKey}
                className="profile-btn-show"
                description="Instagram"
                // onClick={openLinks}
                onClick={() => {
                  if (links[1])
                    openLink(`https://www.instagram.com/${links[1]}`);
                }}
                icon={<FaInstagram />}
              />
            )}
            {links[2] && (
              <Control
                letter="3"
                activeKey={activeKey}
                className="profile-btn-show"
                description="Twitter"
                // onClick={openLinks}
                onClick={() => {
                  if (links[2]) openLink(`https://twitter.com/${links[2]}`);
                }}
                icon={<FaTwitter />}
              />
            )}
            {links[3] && (
              <Control
                letter="4"
                activeKey={activeKey}
                className="profile-btn-show"
                description="Tiktok"
                // onClick={openLinks}
                onClick={() => {
                  if (links[3]) openLink(`https://www.tiktok.com/@${links[3]}`);
                }}
                icon={<FaTiktok />}
              />
            )}
            <Control
              letter="L"
              activeKey={activeKey}
              className="profile-btn-show"
              description="Open All"
              // onClick={openLinks}
              onClick={() => {
                if (links.length > 0) {
                  openLinks(links);
                  setShowLinks(false);
                }
              }}
              icon={<FaAngleDoubleRight />}
            />
          </>
        )}
        {/* <Control
          onClick={()=>handleSetCopy(id)}
          // letter="c"
          letter="q"
          activeKey={activeKey}
          description="Copy"
          icon={<FaBan />}
        /> */}
      </div>
      {textPrompt && activePost !== -1 && (
        <div className="controls">
          <Control
            letter="a"
            activeKey={activeKey}
            description="Return"
            icon={<RiArrowGoBackLine />}
            onClick={() => setTextPrompt(false)}
          />
          <Control
            letter="1"
            activeKey={activeKey}
            description="Bio"
            icon={<RiArrowRightLine />}
            // onClick={deleteBio}
            onClick={newbioapi}
          />
          <Control
            letter="2"
            activeKey={activeKey}
            description="Username"
            icon={<RiArrowRightLine />}
            onClick={deleteUsername}
          />
          <Control
            letter="3"
            activeKey={activeKey}
            description="profile"
            icon={<RiArrowRightLine />}
            // onClick={deleteVerificationImg}
            onClick={updateprifilepic}
          />
          <Control
            letter="4"
            activeKey={activeKey}
            description="Caption"
            icon={<RiArrowRightLine />}
            onClick={deletePostTextFunction}
          />
        </div>
      )}
      <div
        className={
          activePost === -1 || activePost >= posts.length || !reasonPrompt
            ? "controls hidden"
            : "controls"
        }
      >
        <Control
          letter="a"
          activeKey={activeKey}
          description="Back"
          icon={<RiArrowGoBackLine />}
          onClick={() => setReasonPrompt(false)}
        />
        <Control
          letter="1"
          activeKey={activeKey}
          description="Nudity / Partial"
          icon={<FaBan />}
          onClick={deleteForNudity}
        />
        <Control
          letter="2"
          activeKey={activeKey}
          description="Underage"
          icon={<FaBan />}
          onClick={deleteForUnderage}
        />
        <Control
          letter="3"
          activeKey={activeKey}
          description="Selling / Self-Promotion"
          icon={<FaBan />}
          onClick={deleteForSelling}
        />
        <Control
          letter="4"
          activeKey={activeKey}
          description="Suggestive / Sexual Suggestion"
          icon={<FaBan />}
          onClick={deleteforSexual}
        />
        <Control
          letter="5"
          activeKey={activeKey}
          description="Scam"
          icon={<FaBan />}
          onClick={() =>
            deletePost({
              reason_report_int: 5,
              reason_report: "Scam",
            })
          }
        />
        <Control
          letter="6"
          activeKey={activeKey}
          description="Illegal"
          icon={<FaBan />}
          onClick={() =>
            deletePost({
              reason_report_int: 6,
              reason_report: "Illegal",
            })
          }
        />
        <Control
          letter="0"
          activeKey={activeKey}
          description="Against Guidelines"
          icon={<FaBan />}
          onClick={() =>
            deletePost({
              reason_report_int: 0,
              reason_report: "Against guidelines",
            })
          }
        />
      </div>
      <div className={!banPrompt ? "controls hidden" : "controls"}>
        <Control
          letter="a"
          activeKey={activeKey}
          description="Return"
          icon={<RiArrowGoBackLine />}
          onClick={() => setBanPrompt(false)}
        />
        <Control
          letter="1"
          activeKey={activeKey}
          description="Banned"
          icon={<FaBan />}
          onClick={ban}
        />
        <Control
          letter="2"
          activeKey={activeKey}
          description="Hide"
          icon={<FaBan />}
          onClick={hide}
        />
        <Control
          letter="3"
          activeKey={activeKey}
          description="Supreme"
          icon={<FaBan />}
          onClick={supreme}
        />
      </div>
    </>
  );
}
