import { useEffect, useState } from "react";

export default function ModerationDetails(props) {
  const [utcTime, setUtcTime] = useState("");

  function renderModStatus(mod_res, mod_reason, mod_age_req) {
    let statusString = "";
    if (mod_res === 1) {
      statusString = "Safe";
    } else if (mod_res === 2 && mod_reason === "selling") {
      statusString = "Notify • Selling";
    } else if (mod_res === 2) {
      statusString = "Notify";
    } else {
      statusString = "Ban";
    }
    if (mod_age_req){
      statusString += " • Age"
    }
    return statusString;
  }

  useEffect(() => {
    function convertSecondsToBeirutTime(seconds) {
      console.log("Unix Time:", seconds)
      // Create a Date object from the Unix timestamp (in seconds)
      const date = new Date(seconds * 1000);

      // Format the date for Beirut time using the "Asia/Beirut" timeZone.
      const options = {
        timeZone: "Asia/Beirut", // This ensures the time is shown in Beirut time.
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      return date.toLocaleString("en-US", options);
    }

    const seconds = props?.last_report_date;
    if (seconds) {
      const beirutTimeString = convertSecondsToBeirutTime(seconds);
      setUtcTime(beirutTimeString);
      console.log("Manually Adjusted Beirut Time:", beirutTimeString); // For debugging
    }
  }, [props?.last_report_date]);

  return (
    <div className="moderation-details">
      <div className="detail-row">
        <strong>
          Res: {renderModStatus(props?.mod_res, props?.mod_reason, props?.mod_age_req)}
        </strong>
      </div>
      <div className="detail-row">
        <span>
          Comment:{" "}
          {props?.mod_comment === "No Reason" ? (
            <span>ø</span>
          ) : (
            <span>{props?.mod_comment}</span>
          )}
        </span>
      </div>
      <div className="detail-row">
        <span>Mod: {props?.mod_uid}</span>
      </div>
      <div className="detail-row">
        <span>Date: {utcTime}</span>
      </div>
    </div>
  );
}
