import dayjs from "dayjs";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import {
  selectDetailsLoading,
  selectIsHistory,
  selectShowAgeSuccess,
  selectShowUiLoading,
} from "../../chatSlice";
import { Skeleton } from "@mui/material";
import AvatarWithFallback from "./AvatarWithFallback";

const LeftVertical = forwardRef(({ post, onMouseEnter, onMouseLeave }, ref) => {
  const detailsLoading = useSelector(selectDetailsLoading);
  const showUiLoading = useSelector(selectShowUiLoading);
  const isHistory = useSelector(selectIsHistory);
  const loading = (detailsLoading && !post?.startedFetching) || showUiLoading;
  const {
    // last_report_date = "",
    total_report_counter = "",
    last_mod_date = "",
    last_mod_uid = "",
    open_report_counter = "",
    warn_counter_thirty_days = "",
    warn_counter = "",
    date_thirty_days = "",
    id = "",
    uid = "",
    roomID = "",
    uid_reported = "",
    uid_reporting = "",
    reason = "",
    chat = [],
    chatLastSeen = {},
    reportedUsername = "",
    reportedUserID = "",
    reportedProfileImage = "",
    reportedVerifyImage = "",
    reportedAge = "",
    reportedCountry = "",
    reportedGender = "",
    reportingUsername = "",
    reportingUserID = "",
    reportingProfileImage = "",
    reportingVerifyImage = "",
    reportingAge = "",
    reportingGender = "",
    postID = "",
    closeCase = [],
    openCase = [],
    ipThreat = "",
  } = post;
  return (
    <div
      className="left-vertical-container"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="font-l">Reported User</div>
      <AvatarWithFallback
        src={reportedProfileImage}
        alt="Profile Image not available"
        isSquare={true}
        style={{
          margin: 0,
        }}
      />
      <div>
        {" "}
        {loading ? (
          <Skeleton
            variant="text"
            sx={{
              fontSize: "1rem",
              backgroundColor: "white",
              height: "50px",
              width: "100px",
            }}
          />
        ) : (
          <span
            style={{
              fontSize: "clamp(18px, 1.5vw, 28px)",
              fontWeight: "bold",
            }}
          >
            {" "}
            {reportedUsername}
          </span>
        )}
      </div>
      <div className="bold font-l">
        {loading ? (
          <Skeleton
            variant="text"
            sx={{
              fontSize: "1rem",
              backgroundColor: "white",
              height: "50px",
              width: "150px",
            }}
          />
        ) : (
          <span
            className="no-bold font-xxl"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "23px",
            }}
          >
            {reportedGender === 0 ? "🙋🏻‍♂️" : "🙋🏻‍♀️"}{" "}
            {reportedCountry === "🇳🇬" ? "•" : `• ${reportedCountry} •`}{" "}
            {reportedAge}
            {" yo"}
          </span>
        )}
      </div>
      {ipThreat && (
        <div className="font-m">
          IP Threat:{" "}
          {loading ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", backgroundColor: "white" }}
            />
          ) : (
            <span className="bold font-m">{ipThreat}</span>
          )}
        </div>
      )}
      {!loading && (
        <>
          <AvatarWithFallback
            src={reportedVerifyImage}
            alt="Verify Image not available"
            isSquare={false}
            style={{
              margin: 0,
              borderRadius: "10px",
            }}
            height={200}
            width={120}
          />
        </>
      )}
      {!isHistory && (
        <>
          <div className="w-100">
            <h5>
              {openCase?.length >= 2 && "🔴"} Open Cases ({openCase?.length})
            </h5>
            {openCase?.map((x) => {
              return (
                <>
                  <div className="table-chat">
                    <div className="user-get">
                      <div className="user-get-text">
                        <h4>{x?.username_reporting}</h4>
                        <p>{dayjs.unix(x?.date?.seconds).fromNow()}</p>
                      </div>
                      <div className="user-get-text">
                        <p className="w-fix">Text</p>
                        <p className="w-fix">{x?.reason}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="w-100 mt-4">
            <h5>Close Cases ({closeCase?.length})</h5>
            {closeCase?.map((x) => {
              return (
                <>
                  <div className="table-chat">
                    <div className="user-get">
                      <div className="user-get-text">
                        <h4>{x?.username_reporting}</h4>
                        {/* <p>{props?.roomIdData.openCasePostsSinceDate}</p> */}
                        <p>{dayjs.unix(x?.date?.seconds).fromNow()}</p>
                      </div>
                      <div className="user-get-text">
                        <p className="w-fix">Text</p>
                        <p className="w-fix">{x?.reason}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
});

export default LeftVertical;
