import { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  RiArrowGoBackLine,
  RiDeleteBin2Line,
  RiCheckDoubleFill,
  RiBillLine,
} from "react-icons/ri";
import { FaBan } from "react-icons/fa";
import "./verification.css";
import { ControlButton } from "../components/ControlButton";
import { selectActivePost, setActivePost } from "../posts/postsSlice";
import { VerificationPost } from "./VerificationPost";
import { VerificationHistoryPost } from "./VerificationHistoryPost";
import { VerificationSwipingHistoryPost } from "./VerificationSwipingHistoryPost";
import {
  db,
  sendResultVerification,
  last_post_moderation,
  profile_pic_moderation,
  get_counter_mod,
  age_verif_mod,
} from "../../app/firebase";
import "firebase/firestore";
import firebase from "firebase";
import { Dropdown } from "primereact/dropdown";
import { selectUser } from "../auth/userSlice";
import SearchUser from "../search/SearchUser";
import { Alert, Snackbar } from "@mui/material";
import { ADMIN_UID_TO_NAME } from "./ageVerification/utils/utils";

var lastDocSnapShot = null;

function VerificationComponent(props) {
  const [key, setKey] = useState("");
  const [page, setPage] = useState(0);
  const [posts, setPosts] = useState([]);
  const [historyData, setHistoryData] = useState([[], []]);
  const [swipingHistoryData, setSwipingHistoryData] = useState([[], []]);
  const [info, setInfo] = useState({});
  const [startAfter, setStartAfter] = useState(null);
  const [startAfterSwipingHistory, setStartAfterSwipingHistory] =
    useState(null);
  const [switchS, setSwitchS] = useState(true);
  const [manageBtn, setManageBtn] = useState("addchat");
  const [switchHistory, setSwitchHistory] = useState(false);
  const ref = useRef(null);
  const activePost = useSelector(selectActivePost);
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const [userName, setUserName] = useState();
  const [lastPost, setLastPost] = useState();
  const [profileImage, setProfileImage] = useState();
  let { uid } = useSelector(selectUser);
  const [admins, setAdmins] = useState([{ label: uid, value: uid }]);
  const [adminFilter, setAdminFilter] = useState(null);
  const [filterBy, setFilterBy] = useState(null);
  const [ageOption, setAgeOption] = useState("");
  const [faceMatchOption, setFaceMatchOption] = useState("");
  const [range, setRange] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const currentTimestamp = firebase.firestore.Timestamp.now();
  const [lastDoc, setLastDoc] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [countResponse, setCountResponse] = useState("-");
  const location = useLocation();
  const { pathname } = location;
  const isVerificationPage = pathname === "/swiping/verification";
  const [showPopup, setShowPopup] = useState({ show: false, ID: undefined });
  const [showSuccess, setShowSuccess] = useState(false);

  const ageOptions = [
    { label: "Under 16", value: 16 },
    { label: "Under 18", value: 17 },
    { label: "Over 18", value: 19 },
  ];

  const faceMatchOptions = [
    { label: "under 80", value: 79 },
    { label: "over 80", value: 81 },
  ];
  // useEffect(()=>{
  //   if(activePost !== -1) console.log("active post changed:",posts[page][activePost])
  // },[activePost])

  useEffect(() => {
    if (isVerificationPage) {
      swipingHistoryCall();
      setSwitchS(!switchS);
    }
  }, [isVerificationPage]);

  useEffect(() => {
    getCountOfData();
  }, [isVerificationPage, showDropdown]);

  function getVerificationPosts() {
    if (switchS || !switchS) {
      setStartAfter(null);
      setHistoryData([]);
      setSwipingHistoryData([]);
      setSwitchHistory(false);
    }

    let que = !isVerificationPage ? "addchat" : "swiping";
    // let que = switchS ? 'addchat' : 'swiping';

    !isVerificationPage ? setManageBtn("addchat") : setManageBtn("swiping");
    //     switchS ? setManageBtn('addchat') : setManageBtn('swiping');

    let verificationPostsRef = db
      .collection("verif")
      .where("waiting_moderation", "==", true)
      .where("app", "==", que)
      .orderBy("date", "desc")
      .limit(20);

    if (startAfter)
      verificationPostsRef = verificationPostsRef.startAfter(startAfter);
    verificationPostsRef.get().then((verificationPostsSnapshot) => {
      let verificationPosts = [];

      verificationPostsSnapshot.forEach((verificationPost, id) => {
        verificationPosts.push(verificationPost.data());
      });
      // console.log("verificationpostsref", verificationPosts);
      setStartAfter(
        verificationPostsSnapshot.docs[
          verificationPostsSnapshot.docs.length - 1
        ]
      );

      setPosts((newPosts) => {
        return [...newPosts, verificationPosts];
      });
      if (activePost !== -1) {
        if (verificationPosts.length == 0) {
          dispatch(setActivePost(-1));
        } else {
          dispatch(setActivePost(0));
        }
      }
    });
    return { status: 1 };
  }

  useEffect(() => {
    setPageNumber(1);
    getVerificationPosts();
  }, [switchS]);

  const handleHistoryClick = () => {
    setShowDropdown(!showDropdown);

    if (showDropdown) {
      getVerificationPosts();
    } else {
      !isVerificationPage ? historyCall() : swipingHistoryCall();
    }
  };

  useEffect(() => {
    db.collection("verif")
      .doc("info")
      .get()
      .then((infoResponse) => {
        setInfo(infoResponse.data());
        // console.log(infoResponse.data(), '===========infoResponse.data()===========');
      });
  }, [props]);

  const deleteLastProfileImage = async () => {
    await last_post_moderation({
      uid: id,
      username: userName,
      location: "verification",
      imgUrl: lastPost,
    })
      .then((data) => {
        alert(data?.data?.message);
        // console.log(data, '============Data==========');
      })
      .catch((err) => {
        console.log(err, "========Err=======");
      });
  };

  const deleteProfileImage = async () => {
    await profile_pic_moderation({
      userID: id,
      username: userName,
      location: "verification",
      imgUrl: profileImage,
    })
      .then((data) => {
        alert(data?.data?.message);
        // console.log(data, '============Data==========');
      })
      .catch((err) => {
        console.log(err, "========Err=======");
      });
  };

  function handleKeyDown(e) {
    setKey(e.key);
  }

  function next() {
    if (switchHistory && switchS) {
      if (activePost + 1 == historyData[page]?.length) {
        // console.log('This is history function active post');
        handleGoToNextPage();
        dispatch(setActivePost(-1));
      } else {
        console.log("This is history function");
        dispatch(setActivePost(activePost + 1));
      }
    } else if (switchHistory && !switchS) {
      if (activePost + 1 == swipingHistoryData[page]?.length) {
        console.log("This is history function active post");
        handleGoToNextPage();
        dispatch(setActivePost(-1));
      } else {
        console.log("This is Swiping history function");
        dispatch(setActivePost(activePost + 1));
      }
    } else {
      console.log("This is normal function active post");
      if (activePost + 1 == posts[page]?.length) {
        handleGoToNextPage();
        dispatch(setActivePost(-1));
      } else {
        dispatch(setActivePost(activePost + 1));
      }
    }
  }

  function switchQuery() {
    if (activePost === -1) {
      setStartAfter(null);
      setSwitchHistory(false);
      setSwitchS(!switchS);
      setPosts([]);
      setPage(0);
    }
  }

  var selected_mod_uid = adminFilter;
  // "Verification" Section should display data of Addchat and history of Addchat. (no toggle needed there)
  // "Swiping -> Verification" Section should display data of Swiping and it history
  function historyCall() {
    // console.log('This is HistoryCall Function');
    setManageBtn("history");
    setSwitchHistory(true);

    if (switchS) {
      // setHistoryData([]);
      let verificationPostsRef = db
        .collection("verif")
        .where("app", "==", "addchat")
        .where("waiting_moderation", "==", false);

      if (selected_mod_uid) {
        verificationPostsRef = verificationPostsRef.where(
          "by_mod",
          "==",
          selected_mod_uid
        );
      }

      if (ageOption || faceMatchOption) {
        verificationPostsRef = verificationPostsRef.limit(1000);
        console.log("fetching 1000");
      } else verificationPostsRef = verificationPostsRef.limit(20);

      verificationPostsRef = verificationPostsRef
        .where(
          "date_mod_verif",
          "<=",
          range ? new Date(range) : currentTimestamp
        )
        .orderBy("date_mod_verif", "desc");

      if (lastDocSnapShot) {
        verificationPostsRef = verificationPostsRef.startAfter(lastDocSnapShot);
      }

      verificationPostsRef
        .get()
        .then((res) => {
          let verificationPosts = [];

          if (ageOption || faceMatchOption) {
            res.forEach((verificationPost, id) => {
              verificationPosts.push(verificationPost);
            });
            //because we would have fetched 1000 documents and now we need to sort them to get the most recent 20 docs with age below ageOption
            verificationPosts = verificationPosts
              .filter((doc) => {
                const data = doc.data();
                if (ageOption) return data.verif_guess.age < ageOption;
                else if (faceMatchOption)
                  return data.verif_guess.face_match < faceMatchOption;
              })
              .sort(
                (a, b) =>
                  b.data().date_mod_verif.toDate() -
                  a.data().date_mod_verif.toDate()
              ); //we do the data() this way so we can keep track of the last doc
            verificationPosts = verificationPosts.slice(0, 20);
            console.log(verificationPosts?.length);
            const lastVisibleDoc =
              verificationPosts[verificationPosts?.length - 1];

            if (lastDocSnapShot) {
              console.log(
                "lastDoc",
                lastDoc,
                "lastDocSnapShot",
                lastDocSnapShot
              );
              setHistoryData((historyData) => {
                return [
                  ...historyData,
                  verificationPosts?.map((doc) => doc.data()),
                ];
              });
            } else {
              console.log(
                "lastDoc",
                lastDoc,
                "lastDocSnapShot",
                lastDocSnapShot
              );
              setHistoryData((historyData) => {
                return [verificationPosts?.map((doc) => doc.data())];
              });
            }
            console.log("new filtered data:", [
              ...historyData,
              verificationPosts?.map((doc) => doc.data()),
            ]);
            setLastDoc(lastVisibleDoc);
            lastDocSnapShot = lastVisibleDoc;
          } else if (!ageOption && !faceMatchOption) {
            res.forEach((verificationPost, id) => {
              verificationPosts.push(verificationPost.data());
            });
            setLastDoc(res.docs[res.docs.length - 1]);
            lastDocSnapShot = res.docs[res.docs.length - 1];
            setHistoryData((historyData) => {
              console.log("historyData", historyData);
              return [...historyData, verificationPosts];
            });
          }

          // console.log('this is the history data', historyData);
        })
        .catch((err) => {
          console.log(err, "=============Err========");
        });
    }
  }

  const handleFilterClick = () => {
    if (ageOption || faceMatchOption) {
      setHistoryData([[]]);
      setPosts([[]]);
      setLastDoc(null);
      setPageNumber(1);
      setPage(0);
      lastDocSnapShot = null;
      historyCall();
    } else if (ageOption === 0 || faceMatchOption === 0)
      alert("Please enter an value above 0");
    else alert("Please specify a value");
  };

  useEffect(() => {
    // console.log("change admin filyteyr");
    setHistoryData([]);
    setPage(0);
  }, [range, adminFilter]);

  // function handleGoToNextPage() {
  //   setPageNumber((prevPageNumber) => prevPageNumber + 1);
  //   historyCall();
  //   console.log('this is the next history data', historyData);
  // }

  function handleGoToNextPage() {
    if (!switchHistory) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
      setPage((prevPageNumber) => prevPageNumber + 1);
      getVerificationPosts();
    } else if (!switchS && switchHistory) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
      setPage((prevPageNumber) => prevPageNumber + 1);
      swipingHistoryCall();
    } else {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
      setPage((prevPageNumber) => prevPageNumber + 1);
      if (historyData.length == page + 1) {
        historyCall();
      }
      console.log("this is the next history data", historyData);
    }
  }

  function handleGoToPreviousPage() {
    if (!switchHistory) {
      if (page - 1 !== -1) {
        setPageNumber((prevPageNumber) => prevPageNumber - 1);
        setPage(page - 1);
      }
    } else if (!switchS && switchHistory) {
      if (page - 1 !== -1) {
        setPageNumber((prevPageNumber) => prevPageNumber - 1);
        setPage(page - 1);
      }
    } else {
      if (pageNumber > 1) {
        setPageNumber((prevPageNumber) => prevPageNumber - 1);
        setPage(page - 1);
        // const startIndex = (pageNumber - 2) * 20;
        // const endIndex = (pageNumber - 1) * 20;
        // const previousPageData = historyData.slice(startIndex, endIndex);
        // setHistoryData(previousPageData);
        // historyCall();
        // Display the data for the previous page
        // console.log('this is the previous page', previousPageData);
      }
    }
  }

  function swipingHistoryCall() {
    // console.log('This is HistorySwiping Function', switchS, isVerificationPage);
    setManageBtn("history");
    if (!switchS) {
      let verificationPostsRef = db
        .collection("verif")
        .where("app", "==", "swiping")
        .where("waiting_moderation", "==", false)
        .orderBy("date_mod_verif", "desc")
        .limit(20);

      if (startAfterSwipingHistory)
        verificationPostsRef = verificationPostsRef.startAfter(
          startAfterSwipingHistory
        );
      if (selected_mod_uid) {
        console.log("selected_mod_uid", selected_mod_uid);
        verificationPostsRef = verificationPostsRef.where(
          "by_mod",
          "==",
          selected_mod_uid
        );
      }
      verificationPostsRef
        .get()
        .then((res) => {
          let verificationPosts = [];

          res.forEach((verificationPost, id) => {
            verificationPosts.push(verificationPost.data());
          });

          setStartAfterSwipingHistory(res.docs[res.docs.length - 1]);
          // setSwipingHistoryData([...swipingHistoryData, verificationPosts]);
          setSwipingHistoryData((swipingHistoryData) => {
            return [...swipingHistoryData, verificationPosts];
          });
          // console.log("verificationPostsRef", verificationPosts);
          if (activePost == -1) {
            // if (verificationPosts.length == 0) {
            dispatch(setActivePost(-1));
            // } else {
            //   dispatch(setActivePost(-1));
            // }
          } else {
            if (verificationPosts.length == 0) {
              dispatch(setActivePost(-1));
            } else {
              dispatch(setActivePost(0));
            }
          }
        })
        .catch((err) => {
          console.log(err, "=============Err========");
        });
      setSwitchHistory(true);
      // setPage(page + 1); //change
    } else {
      setSwipingHistoryData([]);
      setSwitchHistory(true);
    }
  }

  useEffect(() => {
    // console.log("change admin filyteyr");
    setSwipingHistoryData([]);
    setPage(0);
  }, [range, adminFilter]);

  function handleKeyUp(e) {
    setKey("");
    if (e.key === " ") {
      if (posts[page].length !== 0) {
        dispatch(setActivePost(0));
      }

      if (historyData && historyData[page]?.length !== 0) {
        dispatch(setActivePost(0));
      }
    }

    switch (e.key.toLowerCase()) {
      case "escape":
        if (showPopup?.show)
          return setShowPopup((currentState) => {
            return { show: !currentState.show, ID: undefined };
          });
        return dispatch(setActivePost(-1));

      case "a":
        if (!id) {
          return;
        }
        // return console.log(id);
        age_verif_mod({
          req_age: true,
          uid: id,
        })
          .then((res) => {
            if (res?.data?.status != 1) {
              alert(`age_verif_mod error: ${res?.data?.message}`);
              console.error("age_verif_mod error:", res?.data?.message);
            } else {
              setShowSuccess(true);
            }
          })
          .catch((err) => {
            alert(`age_verif_mod error: ${err}`);
            console.error("age_verif_mod", err);
          });
        return;
      case "arrowright":
        return activePost === -1 ? handleGoToNextPage() : next();

      case "h":
        return activePost === -1 ? handleGoToNextPage() : next();

      case "arrowleft":
        return activePost === -1
          ? handleGoToPreviousPage()
          : dispatch(setActivePost(activePost - 1));

      case "g":
        return activePost === -1
          ? handleGoToPreviousPage()
          : dispatch(setActivePost(activePost - 1));

      case "f":
        return reject();

      case "j":
        return handleJ();

      case "i":
        return ban();
      case "s":
        return switchQuery();

      case "b":
        return switchS ? historyCall() : swipingHistoryCall();

      case "q":
        if ((switchHistory || !switchHistory) && historyData[page]) {
          if (!showPopup.show && historyData[page][activePost].uid)
            setShowPopup((currentState) => {
              return { show: true, ID: historyData[page][activePost].uid };
            });
          return navigator.clipboard.writeText(
            // historyData[activePost].uid
            historyData[page][activePost].uid
          );
        } else {
          if (!showPopup.show && posts[page][activePost].uid)
            setShowPopup((currentState) => {
              return { show: true, ID: posts[page][activePost].uid };
            });
          return navigator.clipboard.writeText(posts[page][activePost].uid);
        }

      case "l":
        if (switchS && activePost !== -1) {
          return deleteLastProfileImage();
        }

      case "p":
        if (switchS && activePost !== -1) {
          return deleteProfileImage();
        }

      case "7":
        setStartAfter(null);
        return getVerificationPosts();

      default:
        return;
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return function () {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  });

  const handleJ = () => {
    if (activePost === -1) return;
    // if(posts[page][activePost]?.verif_guess?.age < 16 || posts[page][activePost]?.verif_guess?.liveliness === 'fake' || posts[page][activePost]?.verif_guess?.face_match < 80) return alert('are you sure you want to accept this user?')
    // else return accept();
    else if (
      (!switchS || switchS) && //if we are in verif we check if the post is flagged and prompt the user to click enter
      historyData.length == 0 &&
      swipingHistoryData.length == 0
    ) {
      if (
        posts[page][activePost]?.verif_guess?.age < 16 ||
        posts[page][activePost]?.verif_guess?.liveliness === "fake" ||
        posts[page][activePost]?.verif_guess?.face_match < 80
      ) {
        let userConfirmed = handlePrompt("enter");
        if (userConfirmed) return accept();
        else return console.log("rejecting");
      } else return accept();
    } else if (switchHistory && switchS && historyData[page]) {
      //if we are in verif history we check if the post is flagged and prompt the user to type yes
      if (
        historyData[page][activePost]?.verif_guess?.age < 16 ||
        historyData[page][activePost]?.verif_guess?.liveliness === "fake" ||
        historyData[page][activePost]?.verif_guess?.face_match < 80
      ) {
        let userConfirmed = handlePrompt("yes");
        if (userConfirmed) return accept();
        else return console.log("rejecting");
      } else return accept();
    }
  };

  const handlePrompt = (type) => {
    let [confirm, userInput] = [false, null];
    if (type === "enter") {
      confirm = window.confirm(
        `are you sure you want to accept a flagges user? Enter to continue:`
      );
    } else if (type === "yes") {
      userInput = window.prompt(
        `are you sure you want to accept a flagged user? Type yes to continue:`
      );
    }

    if (userInput === null && confirm === false) {
      // User clicked 'Cancel'
      console.log("User canceled the prompt.");
      return false;
    }
    if (type === "yes") {
      if (userInput.toLowerCase() === "yes") {
        return true;
      } else {
        return false;
      }
    } else if (type === "enter") {
      if (confirm) {
        return true;
      } else {
        return false;
      }
    }
  };

  function accept() {
    const uid =
      switchHistory && !switchS && swipingHistoryData[page]
        ? swipingHistoryData[page][activePost]?.uid
        : switchHistory && switchS && historyData[page]
        ? historyData[page][activePost]?.uid
        : posts[page][activePost]?.uid;

    sendResultVerification({ uid: uid, res: 1 })
      .then((res) => {})
      .catch((err) => console.log(err));
    next();
  }

  function reject() {
    if (activePost === -1) return;

    const uid =
      switchHistory && !switchS && swipingHistoryData[page]
        ? swipingHistoryData[page][activePost]?.uid
        : switchHistory && switchS && historyData[page]
        ? historyData[page][activePost]?.uid
        : posts[page][activePost]?.uid;
    sendResultVerification({ uid: uid, res: 2 })
      .then((res) => {})
      .catch((err) => console.log(err));
    next();
  }

  function ban() {
    if (activePost === -1) return;
    const uid =
      switchHistory && !switchS && swipingHistoryData[page]
        ? swipingHistoryData[page][activePost]?.uid
        : switchHistory && switchS && historyData[page]
        ? historyData[page][activePost]?.uid
        : posts[page][activePost]?.uid;
    sendResultVerification({ uid: uid, res: 3 })
      .then((res) => {})
      .catch((err) => console.log(err));
    next();
  }

  function nextPage() {
    console.log(
      "very-------------------",
      page,
      posts.length,
      posts,
      "--",
      posts.length - 1 < page + 1
    );

    if (switchHistory && switchS && historyData[page]) {
      if (historyData.length - 1 < page + 1) {
        historyCall();
      } else {
        if (activePost !== -1) {
          dispatch(setActivePost(0));
          console.log("active---post==== -1", activePost);
        }
      }
      setPage(page + 1);
      console.log(activePost);
    } else if (switchHistory && !switchS && swipingHistoryData[page]) {
      if (swipingHistoryData.length - 1 < page + 1) {
        swipingHistoryCall();
      } else {
        if (activePost !== -1) {
          dispatch(setActivePost(0));
          console.log("active---post==== -1", activePost);
        }
      }
      setPage(page + 1);
      console.log(activePost);
    } else {
      if (posts.length - 1 < page + 1) {
        getVerificationPosts();
      } else {
        if (activePost !== -1) {
          dispatch(setActivePost(0));
          console.log("active---post==== -1", activePost);
        }
      }
      setPage(page + 1);
      console.log(activePost);
    }
  }

  function previousPage() {
    if (page - 1 >= 0) {
      setPage(page - 1);
    }
  }
  //code
  function loadAdmins() {
    const adminsArray = [];
    db.collection("admin")
      .where("admin", "==", true)
      .get()
      .then((snapshot) => {
        snapshot.forEach((admin) => {
          const { userID } = admin.data();
          adminsArray.push({
            label: ADMIN_UID_TO_NAME[userID] || userID,
            value: userID,
          });
        });
        adminsArray.sort((a, b) => a.label.localeCompare(b.label));
        if (uid) {
          const currentAdminIndex = adminsArray.findIndex(
            (admin) => admin.value === uid
          );
          if (currentAdminIndex !== -1) {
            const [currentAdminEntry] = adminsArray.splice(
              currentAdminIndex,
              1
            );
            adminsArray.unshift(currentAdminEntry);
          }
        }
        setAdmins(adminsArray);
      });
  }

  useEffect(loadAdmins, []);

  const getCountOfData = async () => {
    let count;
    if (showDropdown) {
      setCountResponse("-");
      return;
    }
    if (pathname === "/swiping/verification") {
      count = await get_counter_mod({
        app: "swiping",
        section: "swiping",
        subsection: "verification",
      });
    } else {
      count = await get_counter_mod({
        app: "addchat",
        section: "verification",
      });
    }
    if (count?.data?.status === 1) {
      setCountResponse(count.data.counter);
    }
    // console.log(count, "count");
  };

  const verifAge = () => {
    if (!id) {
      return;
    }
    // return console.log(id);
    age_verif_mod({
      req_age: true,
      uid: id,
    })
      .then((res) => {
        if (res?.data?.status != 1) {
          console.error("age_verif_mod error:", res?.data?.message);
          alert(`age_verif_mod error: ${res?.data?.message}`);
        } else {
          setShowSuccess(true);
        }
      })
      .catch((err) => {
        alert(`age_verif_mod error: ${err}`);
        console.error("age_verif_mod", err);
      });
  };

  const renderedPosts = useMemo(() => {
    if (
      (!switchS || switchS) &&
      historyData.length === 0 &&
      swipingHistoryData.length === 0
    ) {
      return posts[page]?.map((post, index) => (
        <VerificationPost
          key={post.uid || index}
          setId={setId}
          setUserName={setUserName}
          setLastPost={setLastPost}
          setProfileImage={setProfileImage}
          postId={index}
          {...post}
        />
      ));
    }
    return null;
  }, [switchS, historyData, swipingHistoryData, posts, page]);

  const renderedHistoryPosts = useMemo(() => {
    console.log("historyData[page]: ", historyData[page]);
    if (switchHistory && switchS && Array.isArray(historyData[page])) {
      return historyData[page]?.map((post, index) => (
        <VerificationHistoryPost
          key={post.uid || index}
          setId={setId}
          setUserName={setUserName}
          setLastPost={setLastPost}
          setProfileImage={setProfileImage}
          postId={index}
          {...post}
        />
      ));
    }
    return [];
  }, [switchHistory, switchS, historyData, page]);

  const renderedSwipingHistoryPosts = useMemo(() => {
    if (switchHistory && !switchS && swipingHistoryData[page]) {
      return swipingHistoryData[page]?.map((post, index) => (
        <VerificationSwipingHistoryPost
          key={post.uid || index}
          setId={setId}
          setUserName={setUserName}
          setLastPost={setLastPost}
          setProfileImage={setProfileImage}
          postId={index}
          {...post}
        />
      ));
    }
    return null;
  }, [switchHistory, switchS, swipingHistoryData, page]);

  return (
    <>
      {showPopup.show && showPopup.ID && (
        <div
          style={
            showPopup?.show && showPopup?.ID
              ? {
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  width: "100vw",
                  height: "100vh",
                  zIndex: 900,
                  position: "fixed",
                  top: 0,
                  left: 0,
                }
              : {}
          }
        >
          <div
            className="chatpopup_container"
            style={{
              position: "fixed", // Or 'absolute', if you prefer
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", // Centers the div
              width: "80vw",
              border: "2px solid grey",
              borderRadius: "10px",
              backgroundColor: "white",
              zIndex: 900,
              // Ensure content is scrollable if it overflows
              height: "90vh",
              overflowY: "scroll",
            }}
          >
            <button
              className="close"
              style={{ position: "sticky" }}
              onClick={() =>
                setShowPopup((currentState) => {
                  return { show: !currentState.show, ID: undefined };
                })
              }
            >
              Close
            </button>
            <SearchUser
              deletePosts={false}
              isDevelopment={false}
              userID={showPopup.ID ? showPopup.ID : undefined}
            />
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSuccess}
        autoHideDuration={2500}
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          "Successfully sent user to Age Verification"
        </Alert>
      </Snackbar>
      <div className="verification-counter">
        {/* { (window.location.pathname === '/swiping/verification' && countResponse) || historyData?.length > 0
          ? 20
          : switchS
            ? info.counter_waiting_addchat
            : info.counter_verif_waiting } */}
        {countResponse}
      </div>
      <div
        style={{
          position: "absolute",
          left: "5vw",
          top: "72px",
          fontWeight: "bold",
          fontSize: "15px",
        }}
      >
        {" "}
        {switchS && info?.last_mod_verif_addchat && info?.last_mod_addchat ? (
          <p
            onClick={() => navigator.clipboard.writeText(info.last_mod_addchat)}
          >
            {" "}
            {dayjs.unix(info.last_mod_verif_addchat.seconds).fromNow({
              compact: true,
              ss: true,
            }) +
              " • " +
              (info.last_mod_addchat
                ? info.last_mod_addchat.slice(0, 8) + "..."
                : "")}{" "}
          </p>
        ) : (
          <p></p>
        )}
        {!switchS && info?.last_mod_verif && info?.last_mod ? (
          <p onClick={() => navigator.clipboard.writeText(info.last_mod)}>
            {" "}
            {dayjs.unix(info.last_mod_verif.seconds).fromNow({
              compact: true,
              ss: true,
            }) +
              " • " +
              (info.last_mod ? info.last_mod.slice(0, 8) + "..." : "")}{" "}
          </p>
        ) : (
          <p></p>
        )}
      </div>

      <div className="pagination">
        {pageNumber > 1 && <div onClick={handleGoToPreviousPage}>Back</div>}
        <div>{pageNumber}</div>
        <div onClick={handleGoToNextPage}>Next {" >"}</div>
      </div>
      {/* {isVerificationPage ? (
        <div ref={ref} className="swiping_div">
        <label className="switch">
          <input
            type="checkbox"
            checked={switchS}
            onChange={(e) => switchQuery()}
          />
          <span className="slider round"></span>
        </label>
      </div>
        ) : (
            null
        )} */}

      {activePost === -1 && (
        <div
          ref={ref}
          className="swiping_div"
          style={{ top: "4.5vw", right: "6vw" }}
        >
          <button
            onClick={() => {
              setPage(0);
              setPageNumber(1);
              handleHistoryClick();
              // !isVerificationPage ? historyCall() : swipingHistoryCall();
            }}
            style={{
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          >
            <img src="/history.png" width="25px" />
          </button>
        </div>
      )}
      {showDropdown && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              alignItems: "flex-start",
              justifyContent: "center",
              marginLeft: "10px",
            }}
          >
            <div className="filterBy">
              <Dropdown
                options={[
                  { label: "Age", value: "age" },
                  { label: "Face Match", value: "face_match" },
                ]}
                onChange={(e) => {
                  setFilterBy(e.value);
                  setFaceMatchOption("");
                  setAgeOption("");
                }}
                value={filterBy}
                showClear
                placeholder="Filter-by"
              />
            </div>
            {filterBy === "age" && (
              <div
                className="ageFilter"
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label>Smaller than:</label>
                <input
                  onChange={(e) => {
                    if (e.target.value === "") return setAgeOption("");
                    else if (isNaN(e.target.value))
                      return alert("Please enter a number");
                    setAgeOption(() => parseInt(e.target.value));
                  }}
                  value={ageOption}
                />
                <button className="custom-button" onClick={handleFilterClick}>
                  Filter
                </button>
              </div>
            )}
            {filterBy === "face_match" && (
              <div
                className="ageFilter"
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label>Smaller than:</label>
                <input
                  onChange={(e) => {
                    if (e.target.value === "") return setFaceMatchOption("");
                    else if (isNaN(e.target.value))
                      return alert("Please enter a number");
                    setFaceMatchOption(() => parseInt(e.target.value));
                  }}
                  value={faceMatchOption}
                />
                <button className="custom-button" onClick={handleFilterClick}>
                  Filter
                </button>
              </div>
            )}
          </div>
          <div
            className="admin-filter"
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "400px",
            }}
          >
            <Dropdown
              options={admins}
              onChange={(e) => {
                selected_mod_uid = e.value;
                setAdminFilter(e.value);
                setLastPost(null);
                setPageNumber(1);

                lastDocSnapShot = null;
                !isVerificationPage ? historyCall() : swipingHistoryCall();
                console.log("New selection : ", e.value);
              }}
              value={adminFilter}
              showClear
              placeholder="All Admins"
            />

            <input
              placeholder="All Admins"
              style={{ marginTop: "10px", height: "28px" }}
              type="datetime-local"
              onChange={(e) => {
                if (!e.target.value) {
                  setRange(new Date().getTime());
                  !isVerificationPage ? historyCall() : swipingHistoryCall();
                }
                const selectedDate = new Date(e.target.value).getTime();
                const currentDate = new Date().getTime();

                if (selectedDate <= currentDate) {
                  setRange(e.target.value);
                  !isVerificationPage ? historyCall() : swipingHistoryCall();
                } else {
                  // Handle case where selected date is in the future
                  console.log("Selected date is in the future.");
                }
              }}
              value={range}
            />
          </div>
        </div>
      )}
      {/* {console.log(posts,"posts")} */}
      {/* {console.log(page,"page")} */}
      {/* {console.log(pageNumber,"pageNumber")} */}
      {/* {console.log(historyData,"historyData")} */}
      {/* {console.log(swipingHistoryData,"swipingHistoryData")} */}
      <div className="block verification-posts">
        {renderedPosts}
        {renderedHistoryPosts}
        {renderedSwipingHistoryPosts}

        <div className={activePost === -1 ? "controls hidden" : "controls"}>
          <ControlButton
            letter="e"
            activeKey={key}
            description="Return"
            icon={<RiArrowGoBackLine />}
            onClick={() => dispatch(setActivePost(activePost - 1))}
          />

          <ControlButton
            letter="f"
            activeKey={key}
            description="Reject"
            icon={<RiDeleteBin2Line />}
            onClick={reject}
          />

          <ControlButton
            letter="j"
            activeKey={key}
            description="Accept"
            icon={<RiCheckDoubleFill />}
            onClick={handleJ}
          />

          <ControlButton
            letter="i"
            activeKey={key}
            description="Ban"
            icon={<FaBan />}
            onClick={ban}
          />

          <ControlButton
            letter="a"
            activeKey={key}
            description="Age Verif"
            icon={<FaBan />}
            onClick={verifAge}
          />

          {switchS && activePost !== -1 && (
            <ControlButton
              letter="l"
              activeKey={key}
              description="Last Post"
              icon={<RiDeleteBin2Line />}
              onClick={deleteLastProfileImage}
            />
          )}
          {switchS && activePost !== -1 && (
            <ControlButton
              letter="p"
              activeKey={key}
              description="Profile Pic"
              icon={<RiDeleteBin2Line />}
              onClick={deleteProfileImage}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default VerificationComponent;
