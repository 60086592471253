import { Button } from "@mui/material";
import { setDetailsLoading } from "../../chatSlice";
import useChats from "../../useChats";
import { useDispatch, useSelector } from "react-redux";
export default function Buttons() {
  const dispatch = useDispatch();
  const { handleModeration } = useChats();
  const { activePost, posts, page } = useSelector((state) => state.chats);
  const buttonStyle = {
    backgroundColor: "white",
    color: "black",
    borderRadius: "75px",
    border: "1px solid #dcdcdc",
    width: "60px",
    height: "50px",
    fontSize: "1rem",
    padding: "10px 18px",
    "&:hover": {
      backgroundColor: "lightgrey",
      color: "white",
      border: "none",
      boxShadow: "none",
    },
  };
  return (
    <div className="middle-container-buttons">
      <Button
        endIcon={<span style={{ fontWeight: "bold" }}>F</span>}
        variant="outlined"
        sx={buttonStyle}
        onClick={() => {
          handleModeration(activePost, {
            action: "notify",
            data: posts[page][activePost],
          });
        }}
      >
        Notify
      </Button>
      <Button
        endIcon={<span style={{ fontWeight: "bold" }}>J</span>}
        variant="outlined"
        sx={buttonStyle}
        onClick={() => {
          handleModeration(activePost, {
            action: "keep",
            data: posts[page][activePost],
          });
        }}
      >
        Safe
      </Button>
      <Button
        endIcon={<span style={{ fontWeight: "bold" }}>I</span>}
        variant="outlined"
        sx={buttonStyle}
        onClick={() => {
          handleModeration(activePost, {
            action: "ban",
            data: posts[page][activePost],
          });
        }}
      >
        Ban
      </Button>
      <Button
        endIcon={<span style={{ fontWeight: "bold" }}>Z</span>}
        variant="outlined"
        sx={buttonStyle}
        onClick={() => {
          handleModeration(activePost, {
            action: "selling",
            data: posts[page][activePost],
          });
        }}
      >
        Selling
      </Button>
      <Button
        endIcon={<span style={{ fontWeight: "bold" }}>A</span>}
        variant="outlined"
        sx={buttonStyle}
        onClick={() => {
          handleModeration(activePost, {
            action: "age",
            data: posts[page][activePost],
          });
        }}
      >
        Age
      </Button>{" "}
    </div>
  );
}
