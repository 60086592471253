import React, { useEffect, useRef, useState } from 'react'
import './ageStyles.css'
import { fetchNext, fetchPosts, fetchPostsByMod, loadAdmins } from './utils/utils'
import Grid5 from './utils/Grid5'
import { useDispatch, useSelector } from 'react-redux'
import { changePage, clearPosts, selectActivePost, selectFetchNext, selectPage, selectPosts, selectStartAfter, setActivePost, setFetchNext, setPosts, setStartAfter } from '../../posts/postsSlice'
import { Box, CircularProgress, Snackbar, Alert } from '@mui/material'
import PageNavigation from './utils/PageNavigation'
import SearchUserPopup from './utils/SearchUserPopup'
import { set } from 'react-hook-form'
import { selectUser } from '../../auth/userSlice'
import { logAnalytics } from '../../../app/firebase'

export default function AgeVerification() {
  const activePost = useSelector(selectActivePost)
  const dispatch = useDispatch();
  let posts = useSelector(selectPosts);
  let page = useSelector(selectPage);
  let startAfter = useSelector(selectStartAfter);
  let isFetchNext = useSelector(selectFetchNext);
  const [loading, setLoading] = useState(true);
  const [dataArrived, setDataArrived] = useState(false);
  const [showPopup, setShowPopup] = useState({ show: false, ID: undefined });
  const [noMoreData, setNoMoreData] = useState(false);
  const pressesCounterRef = useRef(0);
  const [showAlert, setShowAlert] = useState(false);
  const [isHistory, setIsHistory] = useState(false);
  let { uid } = useSelector(selectUser);
  const [admins, setAdmins] = useState([{ label: uid, value: uid }]);
  const [adminFilter, setAdminFilter] = useState(uid);

  const handleKeyDown = (e) => {
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
      return //The key was pressed inside an input or textarea.
    }
    // ACTIVE post
    if (activePost !== -1) {
      switch (e.key) {
        case 'Escape':
          if (showPopup?.show) return setShowPopup({ show: false, ID: undefined })
          else dispatch(setActivePost(-1));
          break;
        case 'ArrowRight':
          e.preventDefault();
          if (showPopup?.show) return;
          console.log("right arrow pressed");
          if (activePost < posts[page]?.length - 1) {
            logAnalytics({
              event_type: "next_history_webapp",
              data: {
                mod_uid: posts[activePost]?.mod_uid,
                location: "age_verif"
              }
            }).catch((err) => console.error("log err", err));
            dispatch(setActivePost(activePost + 1));
          } else {
            if (page < posts?.length - 1) {
              setLoading(true);
              dispatch(setActivePost(0));
              dispatch(changePage(page + 1));
            }
            else {
              fetchNextPage(true);
            }
          }
          break;
        case 'ArrowLeft':
          e.preventDefault();
          if (showPopup?.show) return;
          console.log("left arrow pressed");
          if (activePost > 0) {
            dispatch(setActivePost(activePost - 1));
          } else {
            if (page === 1) {
              dispatch(setActivePost(-1));
            }
            else {
              setLoading(true);
              dispatch(setActivePost(19)); //last post in the previous page
              dispatch(changePage(page - 1));
            }
          }
          break;
        case 'q':
          e.preventDefault();
          navigator.clipboard.writeText(posts[page][activePost]?.uid || "")
          if (!showPopup.show && posts[page][activePost]?.uid) setShowPopup({ show: true, ID: posts[page][activePost]?.uid })
      }
    }

    // NO active post
    else if (activePost === -1) {
      switch (e.key) {
        case ' ':
          e.preventDefault();
          console.log("space pressed");
          dispatch(setActivePost(0));
          break;
        case 'ArrowRight':
          e.preventDefault();
          console.log("right arrow pressed");
          if (loading) return; //to prevent spamming the next button
          if (page < posts?.length - 1) {
            setLoading(true);
            dispatch(changePage(page + 1));
          } else {
            fetchNextPage(false);
          }
          break;
        case 'ArrowLeft':
          e.preventDefault();
          console.log("left arrow pressed");
          if (page > 1) {
            setLoading(true);
            dispatch(changePage(page - 1));
          } else {
            alert("First Page");
          }
          break;
      }
    }
  }

  useEffect(() => {
    if (dataArrived) setLoading(false);
  }, [dataArrived, page]) // we also have page so we get a fast flash loading to not see weird posts images changing


  useEffect(() => {
    return () => {
      dispatch(clearPosts());
    }
  }, [clearPosts])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  useEffect(() => {
    if (isFetchNext) {
      if (page < posts?.length - 1) {
        setLoading(true);
        dispatch(setActivePost(0));
        dispatch(changePage(page + 1));
      }
      else {
        fetchNextPage(true);
      }
    }
  }, [isFetchNext])

  useEffect(() => {
    loadAdmins(setAdmins, uid);
  }, [loadAdmins, setAdmins, uid]);

  useEffect(() => {
    setNoMoreData(false);
    if (posts?.length > 0) {
      dispatch(clearPosts())
    }
    const fetchData = async () => {
      setDataArrived(false);
      setLoading(true);
      try {
        const [arr, newStartAfter] = await fetchPosts(isHistory, adminFilter);
        dispatch(setPosts({
          posts: arr,
          page: 1
        }))
        dispatch(setStartAfter(newStartAfter))
      } catch (error) {
        console.error("error fetching posts:", error)
      }
      finally {
        setDataArrived(true);
      }
    }

    fetchData();
  }, [isHistory, adminFilter]);

  const fetchNextPage = async (active) => {
    if (noMoreData) {
      if (pressesCounterRef.current < 2) {
        return pressesCounterRef.current++;
      }
      else {
        return setShowAlert(true); //to show popup alert
      }
    }
    setDataArrived(false);
    setLoading(true);
    dispatch(changePage(page + 1))
    if (active) dispatch(setActivePost(0)); //if active to indicate that if we pressed next from an active post we want to stay active, otherwise no
    try {
      const [arr, newStartAfter, length] = await fetchNext(isHistory, startAfter);
      setNoMoreData(length === 0);
      dispatch(setPosts({
        posts: arr,
        page: page + 1
      }))
      dispatch(setStartAfter(newStartAfter))
    } catch (error) {
      console.error("error fetching next page:", error)
    }
    finally {
      setDataArrived(true);
      dispatch(setFetchNext(false));
    }
  }

  const handleClose = () => {
    setShowAlert(false);
  }

  return (
    <div style={{
      backgroundColor: 'white'
    }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={showAlert}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          "No more Data"
        </Alert>
      </Snackbar>

      <SearchUserPopup showPopup={showPopup} setShowPopup={setShowPopup} />

      <div className="container">
        <PageNavigation
          isHistory={isHistory}
          setIsHistory={setIsHistory}
          postsLength={posts[page]?.length}
          fetchNextPage={fetchNextPage}
          loading={loading}
          setLoading={setLoading}
          isLast={page === posts?.length - 1}
          adminFilter={adminFilter}
          setAdminFilter={setAdminFilter}
          admins={admins}
        />
        {loading ?
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress size={100} />
          </Box>
          :
          <Grid5 posts={posts[page]} activePost={activePost} />
        }
      </div>
    </div>

  )
}
