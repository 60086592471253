import { useParams } from "react-router-dom";
import Body from "./components/body/Body";
import Header from "./components/header/Header";
import "./newChat.css";
import FullScreen from "./components/fullscreen/FullScreen";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPreFetchedArray,
  exitFullScreen,
  selectActivePost,
  selectIsHistory,
  selectPage,
  selectPosts,
  selectPreFetchedFirstPostPages,
  selectShowAgeSuccess,
  setPreFetchedFirstPostPages,
  setShowAgeSuccess,
  setIsHistory,
} from "./chatSlice";
import { useEffect } from "react";
import useChats from "./useChats";
import HeaderHistory from "./components/history_header/HeaderHistory";
import { Alert, Snackbar } from "@mui/material";

export default function NewChatComponent() {
  // Extract the id parameter from the URL.
  const { id } = useParams();
  const dispatch = useDispatch();
  // Set Redux history to true if id is present in the URL.
  useEffect(() => {
    if (id) {
      console.log("[DEBUG] URL id found. Dispatching setIsHistory(true)");
      dispatch(setIsHistory(true));
    }
  }, [id, dispatch]);

  // Now we can simply rely on the Redux state.
  const isHistory = useSelector(selectIsHistory);

  const showFullScreen = useSelector(selectActivePost) >= 0;
  const showAgeSuccess = useSelector(selectShowAgeSuccess);
  const posts = useSelector(selectPosts);
  const page = useSelector(selectPage);
  const activePost = useSelector(selectActivePost);
  const preFetchedFirstPostPages = useSelector(selectPreFetchedFirstPostPages);
  const { fetchChats, fetchHistoryChats, fetchChatDetails } = useChats();

  useEffect(() => {
    if (
      !isHistory &&
      posts &&
      posts[page] &&
      !posts[page][0]?.reportedUserID &&
      !preFetchedFirstPostPages.includes(page) &&
      !posts[page][0]?.startedFetching
    ) {
      dispatch(setPreFetchedFirstPostPages(page));
      fetchChatDetails(posts[page][0]?.uid, 0, true);
    }
  }, [
    posts,
    page,
    activePost,
    isHistory,
    preFetchedFirstPostPages,
    dispatch,
    fetchChatDetails,
  ]);

  useEffect(() => {
    if (isHistory) {
      fetchHistoryChats();
    } else {
      fetchChats();
      dispatch(clearPreFetchedArray());
    }
  }, [isHistory, dispatch, fetchChats, fetchHistoryChats]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Check if any post in any page is still processing moderation.
      const hasProcessingPost = posts.some((page) =>
        page.some((post) => post.isProcessingModeration)
      );
      if (hasProcessingPost) {
        event.preventDefault();
        event.returnValue =
          "Process is still running. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [posts]);

  return (
    <div className="chat-container">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showAgeSuccess}
        autoHideDuration={2500}
        onClose={() => {
          dispatch(setShowAgeSuccess(false));
        }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          "Successfully sent user to Age Verification"
        </Alert>
      </Snackbar>
      {isHistory ? (
        <>
          <HeaderHistory id={id} />
        </>
      ) : (
        <>
          <Header />
        </>
      )}
      <Body />
      {showFullScreen && (
        <>
          <FullScreen onClose={exitFullScreen} />
        </>
      )}
    </div>
  );
}
