import { FaBan } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { GrCheckmark } from "react-icons/gr";
import { HiOutlineBan } from "react-icons/hi";
import { SiGoogletranslate } from "react-icons/si";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdArrowBack } from "react-icons/md";
import { FaTiktok } from "react-icons/fa";
import { FaSnapchatGhost } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

import {
  RiArrowGoBackLine,
  RiArrowRightLine,
  RiCheckDoubleFill,
  RiDeleteBin2Line,
} from "react-icons/ri";
import { ControlButton as Control } from "../components/ControlButton";
import { swipingControlButton as ControlSwipe } from "../components/SwipingControlButton";
import { deletePostText } from "./functions/deletePostText";
import { useState } from "react";
// import {handleDeleteUserName} from '../history/HistoryPost'
interface Props {
  activePost: any;
  posts: any;
  reasonPrompt: any;
  banPrompt: any;
  textPrompt: any;
  activeKey: any;
  deleteForUnderage: any;
  deleteforSexual: any;
  deleteForNudity: any;
  deleteForSelling: any;
  keepPost: any;
  displayBan: any;
  setReasonPrompt: any;
  setTextPrompt: any;
  deleteBio: any;
  deleteUsername: any;
  deleteVerificationImg: any;
  deletePost: any;
  ban: any;
  handleBio: any;
  handleAge: any;
  hide: any;
  supreme: any;
  setBanPrompt: any;
  reported: boolean;
  unread: boolean;
  deleteUser: any;
  docID: any;
  handleDeleteUserName: any;
  handleSetCopy: any;
  translates: any;
  deleteNameSwiping: any;
  deleteAgeSwiping: any;
  deleteImgSwiping: any;
  deleteVerifySwiping: any;
  SwipingBanProfile: any;
  SwipingSafeProfile: any;
  editData: any;
  undoDataSwiping: any;
  isImage: any;
  setLastKey: any;
  switchS: any;
  showLinksButton: any;
  openLink: any;
  showLinks: any;
  setShowLinks: any;
  links: any;
  showProfileHistory: any;
  selectedActions: Array<string>;
  handleProfile: any;
  undoSelectAction: any;
  openLinks: any;
}

export function Controls({
  activePost,
  posts,
  reasonPrompt,
  banPrompt,
  textPrompt,
  activeKey,
  deleteForUnderage,
  deleteforSexual,
  deleteForNudity,
  deleteForSelling,
  keepPost,
  displayBan,
  handleBio,
  handleAge,
  setReasonPrompt,
  setTextPrompt,
  setBanPrompt,
  deleteBio,
  deleteUsername,
  deleteVerificationImg,
  deletePost,
  ban,
  hide,
  supreme,
  reported,
  unread,
  deleteUser,
  docID,
  handleDeleteUserName,
  translates,
  handleSetCopy,
  deleteNameSwiping,
  deleteAgeSwiping,
  deleteImgSwiping,
  deleteVerifySwiping,
  SwipingBanProfile,
  SwipingSafeProfile,
  editData,
  undoDataSwiping,
  isImage,
  setLastKey,
  switchS,
  showLinksButton = false,
  openLink,
  showLinks,
  setShowLinks,
  links,
  showProfileHistory,
  selectedActions,
  handleProfile,
  undoSelectAction,
  openLinks,
}: Props): JSX.Element {
  return (
    <>
      <div
        className={
          activePost === -1 ||
          activePost >= posts.length ||
          reasonPrompt ||
          banPrompt ||
          textPrompt ||
          window.location.pathname === "/profile" ||
          switchS
            ? "controls hidden"
            : "controls align-item-center gap-3"
        }
      >
        {isImage !== "f" ? (
          <>
            <ControlSwipe
              letter="f"
              activeKey={activeKey}
              // className="profile-btn-show"
              description="Images"
              onClick={() => setLastKey("f")}
              is_enable={
                editData?.imgOne || editData?.imgTwo || editData?.imgThree
              }
            />

            <ControlSwipe
              letter="v"
              activeKey={activeKey}
              // className="profile-btn-show"
              description="Verify Again"
              onClick={deleteVerifySwiping}
              is_enable={editData?.verified}
            />

            <ControlSwipe
              letter="N"
              activeKey={activeKey}
              // className="profile-btn-show"
              description="Name"
              onClick={deleteNameSwiping}
              is_enable={editData?.name}
            />

            <ControlSwipe
              letter="A"
              activeKey={activeKey}
              // className="profile-btn-show"
              description="Age"
              onClick={deleteAgeSwiping}
              is_enable={editData?.age}
            />

            <ControlSwipe
              letter="B"
              activeKey={activeKey}
              // className="profile-btn-show"
              description="Ban"
              onClick={SwipingBanProfile}
              is_enable={editData?.ban}
            />

            <ControlSwipe
              letter="Z"
              activeKey={activeKey}
              // className="profile-btn-show"
              description="Undo"
              // onClick={() => {
              //     setReasonPrompt(true);
              // }}
              onClick={undoDataSwiping}
            />

            <ControlSwipe
              letter="J"
              activeKey={activeKey}
              // className="profile-btn-show"
              description={
                editData && Object.keys(editData).length > 0 ? "Done" : "Safe"
              }
              onClick={SwipingSafeProfile}
            />
          </>
        ) : (
          <>
            <ControlSwipe
              letter="F"
              activeKey={activeKey}
              // className="profile-btn-show"
              description="return"
              onClick={() => setLastKey()}
            />
            <ControlSwipe
              letter="A"
              activeKey={activeKey}
              // className="profile-btn-show"
              description="imgOne"
              onClick={() => deleteImgSwiping("a")}
              is_enable={editData?.imgOne}
            />

            <ControlSwipe
              letter="S"
              activeKey={activeKey}
              // className="profile-btn-show"
              description="imgTwo"
              onClick={() => deleteImgSwiping("s")}
              is_enable={editData?.imgTwo}
            />

            <ControlSwipe
              letter="D"
              activeKey={activeKey}
              // className="profile-btn-show"
              description="imgThree"
              is_enable={editData?.imgThree}
              onClick={() => deleteImgSwiping("d")}
            />
          </>
        )}
      </div>

      <div
        className={
          activePost === -1 ||
          activePost >= posts.length ||
          reasonPrompt ||
          banPrompt ||
          window.location.pathname === "/swiping/profile" ||
          textPrompt
            ? "controls hidden"
            : "controls"
        }
      >
        {/* <Control
                    letter="a"
                    className="hide"
                    activeKey={activeKey}
                    description="Underage"
                    icon={<FaBan />}
                    onClick={deleteForUnderage}
                />
                <Control
                    letter="s"
                    className="hide"
                    activeKey={activeKey}
                    description="Sexual/ suggestive"
                    icon={<FaBan />}
                    onClick={deleteforSexual}
                />
                <Control
                    letter="d"
                    className="hide"
                    activeKey={activeKey}
                    description="Nudity"
                    icon={<FaBan />}
                    onClick={deleteForNudity}
                />
                <Control
                    letter="v"
                    className="hide"
                    activeKey={activeKey}
                    description="Selling/ Self-promotion"
                    icon={<FaBan />}
                    onClick={deleteForSelling}
                />
                <Control
                    letter="x"
                    className="hide"
                    activeKey={activeKey}
                    description="Against Guidelines"
                    icon={<FaBan />}
                    onClick={() =>
                        deletePost({
                            reason_report_int: 0,
                            reason_report: "Against guidelines",
                        })
                    }
                /> */}
        {!showLinks && !showProfileHistory && (
          <>
            <ControlSwipe
              letter="U"
              activeKey={activeKey}
              description="Username"
              onClick={handleDeleteUserName}
              is_enable={selectedActions.includes("username")}
            />
            <ControlSwipe
              letter="B"
              activeKey={activeKey}
              description="Bio"
              onClick={handleBio}
              is_enable={selectedActions.includes("bio")}
            />
            <ControlSwipe
              letter="P"
              activeKey={activeKey}
              description="Picture"
              onClick={handleProfile}
              is_enable={selectedActions.includes("profile")}
            />
            <ControlSwipe
              letter="A"
              activeKey={activeKey}
              description="Age Verif"
              onClick={handleAge}
              is_enable={selectedActions.includes("age")}
            />
            <ControlSwipe
              letter="Z"
              activeKey={activeKey}
              description="Selling"
              onClick={deleteForSelling}
              is_enable={selectedActions.includes("selling")}
            />
            <ControlSwipe
              letter="I"
              activeKey={activeKey}
              onClick={displayBan}
              description="Ban"
              is_enable={selectedActions.includes("ban")}
            />
            <ControlSwipe
              letter="F"
              activeKey={activeKey}
              className="profile-btn-show"
              description="Undo"
              style={{ background: "grey", color: "white" }}
              onClick={undoSelectAction}
              icon={<GrClose />}
            />
            <ControlSwipe
              letter="J"
              activeKey={activeKey}
              onClick={keepPost}
              description={selectedActions.length > 0 ? "Done" : "Safe"}
            />
            <ControlSwipe
              letter="T"
              activeKey={activeKey}
              className="profile-btn-show"
              description="Translate"
              is_enable={selectedActions.includes("translate")}
              onClick={translates}
            />
            {showLinksButton && (
              <ControlSwipe
                letter="L"
                activeKey={activeKey}
                className="profile-btn-show"
                description="Links"
                // onClick={openLinks}
                onClick={() => setShowLinks(true)}
              />
            )}
          </>
        )}
        {showLinks && (
          <>
            <Control
              letter="A"
              activeKey={activeKey}
              className="profile-btn-show"
              description="Back"
              onClick={() => setShowLinks(false)}
              icon={<MdArrowBack />}
            />
            {links[0] && (
              <Control
                letter="1"
                activeKey={activeKey}
                className="profile-btn-show"
                description="Snapchat"
                // onClick={openLink(links[0])}
                onClick={() => {
                  if (links[0])
                    openLink(`https://www.snapchat.com/add/${links[0]}`);
                }}
                icon={<FaSnapchatGhost />}
              />
            )}
            {links[1] && (
              <Control
                letter="2"
                activeKey={activeKey}
                className="profile-btn-show"
                description="Instagram"
                // onClick={openLinks}
                onClick={() => {
                  if (links[1])
                    openLink(`https://www.instagram.com/${links[1]}`);
                }}
                icon={<FaInstagram />}
              />
            )}
            {links[2] && (
              <Control
                letter="3"
                activeKey={activeKey}
                className="profile-btn-show"
                description="Twitter"
                // onClick={openLinks}
                onClick={() => {
                  if (links[2]) openLink(`https://twitter.com/${links[2]}`);
                }}
                icon={<FaTwitter />}
              />
            )}
            {links[3] && (
              <Control
                letter="4"
                activeKey={activeKey}
                className="profile-btn-show"
                description="Tiktok"
                // onClick={openLinks}
                onClick={() => {
                  if (links[3]) openLink(`https://www.tiktok.com/@${links[3]}`);
                }}
                icon={<FaTiktok />}
              />
            )}
            <Control
              letter="L"
              activeKey={activeKey}
              className="profile-btn-show"
              description="Open All"
              onClick={() => {
                openLinks();
                setShowLinks(false);
              }}
              icon={<FaExternalLinkAlt />}
            />
          </>
        )}
        {/* <Control
                    letter="z"
                    activeKey={activeKey}
                    className="profile-btn-show"
                    description="Selling"
                    // onClick={() => {
                    //     setReasonPrompt(true);
                    // }}
                    onClick={deleteForSelling}
                    icon={<FaBan/>}
                />
                 */}
        {/* <Control
                onClick={handleSetCopy}
                // letter="c"
                letter="q"
                activeKey={activeKey}
                description="Copy"
                icon={<FaBan />}
              /> */}
        {/* <Control
                    letter="i"
                    className="hide"
                    activeKey={activeKey}
                    description="Profile"
                    icon={<RiArrowRightLine />}
                    onClick={() => setTextPrompt(true)}
                /> */}
      </div>
      {textPrompt && activePost !== -1 && (
        <div className="controls">
          <Control
            letter="a"
            activeKey={activeKey}
            description="Return"
            icon={<RiArrowGoBackLine />}
            onClick={() => setTextPrompt(false)}
          />
          <Control
            letter="1"
            activeKey={activeKey}
            description="Bio"
            icon={<RiArrowRightLine />}
            onClick={deleteBio}
          />
          <Control
            letter="2"
            activeKey={activeKey}
            description="Username"
            icon={<RiArrowRightLine />}
            onClick={deleteUsername}
          />
          <Control
            letter="3"
            activeKey={activeKey}
            description="Verification"
            icon={<RiArrowRightLine />}
            onClick={deleteVerificationImg}
          />
          <Control
            letter="4"
            activeKey={activeKey}
            description="Caption"
            icon={<RiArrowRightLine />}
            onClick={() =>
              deletePostText({
                uid: String(activePost.uid),
                collection: reported ? "moderation_post" : "public_post",
                unread,
                reported,
              })
            }
          />
        </div>
      )}
      <div
        className={
          activePost === -1 || activePost >= posts.length || !reasonPrompt
            ? "controls hidden"
            : "controls"
        }
      >
        <Control
          letter="a"
          activeKey={activeKey}
          description="Back"
          icon={<RiArrowGoBackLine />}
          onClick={() => setReasonPrompt(false)}
        />
        <Control
          letter="1"
          activeKey={activeKey}
          description="Nudity / Partial"
          icon={<FaBan />}
          onClick={deleteForNudity}
        />
        <Control
          letter="2"
          activeKey={activeKey}
          description="Underage"
          icon={<FaBan />}
          onClick={deleteForUnderage}
        />
        <Control
          letter="3"
          activeKey={activeKey}
          description="Selling"
          icon={<FaBan />}
          onClick={deleteForSelling}
        />
        <Control
          letter="4"
          activeKey={activeKey}
          description="Suggestive / Sexual Suggestion"
          icon={<FaBan />}
          onClick={deleteforSexual}
        />
        <Control
          letter="5"
          activeKey={activeKey}
          description="Scam"
          icon={<FaBan />}
          onClick={() =>
            deletePost({
              reason_report_int: 5,
              reason_report: "Scam",
            })
          }
        />
        <Control
          letter="6"
          activeKey={activeKey}
          description="Illegal"
          icon={<FaBan />}
          onClick={() =>
            deletePost({
              reason_report_int: 6,
              reason_report: "Illegal",
            })
          }
        />
        <Control
          letter="0"
          activeKey={activeKey}
          description="Against Guidelines"
          icon={<FaBan />}
          onClick={() =>
            deletePost({
              reason_report_int: 0,
              reason_report: "Against guidelines",
            })
          }
        />
      </div>
      <div className={!banPrompt ? "controls hidden" : "controls"}>
        <Control
          letter="a"
          className="profile-btn-show"
          activeKey={activeKey}
          description="Return"
          icon={<RiArrowGoBackLine />}
          onClick={() => setBanPrompt(false)}
        />
        <Control
          letter="1"
          className="profile-btn-show"
          activeKey={activeKey}
          description="Banned"
          // icon={<FaBan />}
          icon={<HiOutlineBan />}
          onClick={ban}
        />
        <Control
          letter="2"
          className="profile-btn-show"
          activeKey={activeKey}
          description="Hide"
          // icon={<FaBan />}
          icon={<HiOutlineBan />}
          onClick={hide}
        />
        <Control
          letter="3"
          className="profile-btn-show"
          activeKey={activeKey}
          description="Supreme"
          // icon={<FaBan />}
          icon={<HiOutlineBan />}
          onClick={supreme}
        />
      </div>
    </>
  );
}
