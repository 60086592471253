import { Avatar, Button, Tooltip, Snackbar, Alert } from "@mui/material";
import { useState, useEffect } from "react";
import { get_counter_mod } from "../../../../app/firebase";
import {
  ADMIN_UID_TO_NAME,
  fetchTotalProfileCount,
} from "../../../../helpers/utils";
import useChats from "../../useChats";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActionTypeOption,
  selectPage,
  selectReportedFilter,
  setActionTypeOption,
  setLoading,
  setReportedFilter,
} from "../../chatSlice";
import { Dropdown } from "primereact/dropdown";

export default function LeftDisplay() {
  const actionTypeOption = useSelector(selectActionTypeOption);
  const reportedFilter = useSelector(selectReportedFilter);
  const [modUID, setModUID] = useState("");
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const actionTypeOptions = [
    { label: "All", value: 0 },
    { label: "Safe", value: 1 },
    { label: "Notify", value: 2 },
    { label: "Selling", value: 3 },
    { label: "ban", value: 4}
  ];
  const showClearIcon = actionTypeOption !== 0;
  const dispatch = useDispatch();

  useEffect(() => {
    const getCount = async () => {
      get_counter_mod({ app: "addchat", section: "chat" })
        .then((count) => {
          if (!count) return console.log("Error fetching total count");
          setCounter(count?.data?.counter || 0);
        })
        .catch((error) => {
          console.error("Error fetching total count:", error);
        });
      fetchTotalProfileCount()
        .then((arr) => {
          if (!arr) return console.log("Error fetching total count");
          setTotalCount(arr[0]);
          setModUID(arr[1]);
          console.log("Count:", arr[0], "Mod UID:", arr[1]);
        })
        .catch((error) => {
          console.error("Error fetching total count:", error);
        });
    };
    getCount();
  }, []);
  const name = ADMIN_UID_TO_NAME[modUID];

  const handleCopyUid = () => {
    navigator.clipboard.writeText(modUID);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSearchUserID = (value) => {
    const searchValue = value.trim() === "" ? null : value;
    dispatch(setReportedFilter(searchValue));
  };

  const handleActionTypeChange = (e) => {
    const newAction = e.value || 0;
    dispatch(setActionTypeOption(newAction));
  };

  return (
    <div className="header-left-display-history">
      <div className="header-left-top">
        <Avatar
          sx={{
            bgcolor: "grey",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          variant="square"
        >
          {counter}
        </Avatar>
        <span>{totalCount}</span>
        <span> •</span>
        <Tooltip title={modUID} placement="bottom-start">
          <Button
            onClick={handleCopyUid}
            sx={{
              margin: 0,
              padding: 0,
              width: "fit-content",
            }}
          >
            {name}
          </Button>
        </Tooltip>
        {/* ******************************* */}
        <Snackbar
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Mod UID copied to clipboard!
          </Alert>
        </Snackbar>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <input
          type="text"
          placeholder="Search by User ID"
          style={{
            height: "30px",
            width: "200px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
          onChange={(e) => handleSearchUserID(e.target.value)}
          value={reportedFilter}
        />
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "3px",
          }}
        >
          <label style={{ fontSize: "18px" }}>Type: </label>
          <Dropdown
            options={actionTypeOptions}
            value={actionTypeOption}
            onChange={handleActionTypeChange}
            placeholder="All"
            showClear={showClearIcon}
            style={{
              height: "35px",
            }}
          />
        </div> */}
      </div>
    </div>
  );
}
