import "./header.css";
import LeftDisplay from "./LeftDisplay";
import Pagination from "./Pagination";
import RightDisplay from "./RightDisplay";

export default function Header() {
  return (
    <div className="header-container">
      <LeftDisplay />
      <Pagination />
      <RightDisplay/>
    </div>
  );
}
