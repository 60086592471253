import HistoryIcon from "@mui/icons-material/History";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPosts,
  selectAdminFilter,
  selectAdmins,
  selectIsHistory,
  selectRange,
  selectReportedFilter,
  setAdminFilter,
  setIsHistory,
  setLoading,
  setRange,
  setReportedFilter,
} from "../../chatSlice";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import useChats from "../../useChats";

export default function RightDisplay() {
  const adminFilter = useSelector(selectAdminFilter);
  const range = useSelector(selectRange);
  const isHistory = useSelector(selectIsHistory);
  const dispatch = useDispatch();
  const admins = useSelector(selectAdmins);
  const { loadAdmins } = useChats();

  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const dd = String(today.getDate()).padStart(2, "0");
  const todayDateString = `${yyyy}-${mm}-${dd}`;

  useEffect(() => {
    loadAdmins();
  }, []);

  const handleDropdownChange = (e) => {
    dispatch(setAdminFilter(e.value));
  };

  const handleDateChange = (e) => {
    dispatch(setRange(e.target.value || new Date()))
  }

  return (
    <div className="header-right-display">
      <Button
        variant="contained"
        size="large"
        style={{
          backgroundColor: isHistory ? "lightgrey" : "white",
          color: "black",
          borderRadius: "50%",
          height: "46px",
          width: "46px",
          minWidth: "40px",
        }}
        onClick={() => {
          dispatch(setLoading(true));
          dispatch(clearPosts());
          dispatch(setIsHistory(!isHistory));
        }}
      >
        {isHistory ? <ArrowBackIcon /> : <HistoryIcon />}
      </Button>

      {isHistory && (
        <div className="header-right-display-filters">
          <Dropdown
            options={admins}
            onChange={handleDropdownChange}
            value={adminFilter}
            showClear
            placeholder="All Admins"
            style={{
              width: '100%'
            }}
          />
          <input
            // placeholder="All Admins"
            style={{ marginTop: "10px", height: "28px" }}
            type="datetime-local"
            max={todayDateString}
            onChange={handleDateChange}
            value={range}
          />
        </div>
      )}
    </div>
  );
}
