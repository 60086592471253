import { Skeleton } from "@mui/material";
import { forwardRef } from "react";
import { selectDetailsLoading, selectShowUiLoading } from "../../chatSlice";
import { useSelector } from "react-redux";
import Buttons from "./Buttons";
import ModerationDetails from "./ModerationDetails";
import AvatarWithFallback from "./AvatarWithFallback";

const MiddleContainer = forwardRef(
  ({ post, onMouseEnter, onMouseLeave }, ref) => {
    const detailsLoading = useSelector(selectDetailsLoading);
    const showUiLoading = useSelector(selectShowUiLoading);
    const loading = (detailsLoading && !post?.startedFetching) || showUiLoading;
    const {
      // last_report_date = "",
      total_report_counter = "",
      last_mod_date = "",
      last_mod_uid = "",
      open_report_counter = "",
      warn_counter_thirty_days = "",
      warn_counter = "",
      date_thirty_days = "",
      id = "",
      uid = "",
      roomID = "",
      uid_reported = "",
      uid_reporting = "",
      reason = "",
      chat = [],
      chatLastSeen = {},
      reportedUsername = "",
      reportedUserID = "",
      reportedProfileImage = "",
      reportedVerifyImage = "",
      reportedAge = "",
      reportedCountry = "",
      reportedGender = "",
      reportingUsername = "",
      reportingUserID = "",
      reportingProfileImage = "",
      reportingVerifyImage = "",
      reportingAge = "",
      reportingGender = "",
      postID = "",
      closeCase = [],
      openCase = [],
      ipThreat = "",
      isHistory = false,
    } = post;
    return (
      <div className="middle-container">
        {/* <!-- Title Section --> */}
        <div className="middle-container-title">
          Reported for:{" "}
          {reason
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </div>

        {/* <!-- Chat Body Section --> */}
        <div
          className={
            isHistory
              ? "middle-container-body-history"
              : "middle-container-body"
          }
          ref={ref}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {loading ? (
            <>
              <div className="chat-message chat-received">
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1rem",
                    backgroundColor: "white",
                    height: "10px",
                    width: "80px",
                  }}
                />
              </div>
              <div className="chat-message chat-sent">
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1rem",
                    backgroundColor: "white",
                    height: "10px",
                    width: "80px",
                  }}
                />
              </div>
              <div className="chat-message chat-received">
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1rem",
                    backgroundColor: "white",
                    height: "10px",
                    width: "80px",
                  }}
                />
              </div>
              <div className="chat-message chat-sent">
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1rem",
                    backgroundColor: "white",
                    height: "10px",
                    width: "80px",
                  }}
                />
              </div>
            </>
          ) : !isHistory ? (
            chat?.map((x) => {
              return x.userID === uid_reported ? (
                <div
                  className={`chat-message chat-received ${x?.type === "photo" ? "image-message" : ""
                    }`}
                >
                  {x?.type !== "photo" ? (
                    x.text
                  ) : (
                    <div
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      user sent a 📸
                    </div>
                  )}
                </div>
              ) : (
                <div className="chat-message chat-sent">
                  {x?.type !== "photo" ? (
                    x.text
                  ) : (
                    <div style={{ color: "black", fontWeight: "bold" }}>
                      user sent a 📸
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            chat?.map((x) => {
              return x.userID === reportedUserID ? (
                <>
                  <div
                    className={`chat-message chat-received ${x?.type === "photo" ? "image-message" : ""
                      }`}
                  >
                    {x?.type !== "photo" ? (
                      x.text
                    ) : (
                      <div
                        style={{
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        user sent a 📸
                      </div>
                    )}
                  </div>
                  {x?.text === "Replied to a Post" && (
                    <div className="chat-message chat-received">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          maxHeight: "300px",
                          maxWidth: "150px",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "100%",
                          }}
                        >
                          Caption: {x?.post_info?.caption}
                        </p>
                        <div
                          style={{
                            height: "200px",
                            width: "100px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <AvatarWithFallback
                            src={x?.post_info?.image_url}
                            alt="Profile Image not available"
                            isSquare={true}
                            style={{
                              margin: 0,
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="chat-message chat-sent">
                    {x?.type !== "photo" ? (
                      x.text
                    ) : (
                      <div style={{ color: "black", fontWeight: "bold" }}>
                        user sent a 📸
                      </div>
                    )}
                  </div>
                  {x?.text === "Replied to a Post" && (
                    <div className="chat-message chat-sent">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          maxHeight: "300px",
                          maxWidth: "150px",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "100%",
                          }}
                        >
                          Caption: {x?.post_info?.caption}
                        </p>
                        <div
                          style={{
                            height: "200px",
                            width: "100px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <AvatarWithFallback
                            src={x?.post_info?.image_url}
                            alt="Profile Image not available"
                            isSquare={true}
                            style={{
                              margin: 0,
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })
          )}
        </div>

        {/* <!-- Buttons Section --> */}
        {isHistory ? <ModerationDetails {...post} /> : <Buttons />}
      </div>
    );
  }
);

export default MiddleContainer;
