import { Avatar, Button, Tooltip, Snackbar, Alert } from "@mui/material";
import { useState, useEffect } from "react";
import { ADMIN_UID_TO_NAME, fetchTotalProfileCount } from "../../../../helpers/utils";
import { get_counter_mod } from "../../../../app/firebase";

export default function LeftDisplay() {
  const [modUID, setModUID] = useState("");
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const getCount = async () => {
      get_counter_mod({ app: "addchat", section: "chat" }).then((count)=>{
        if(!count) return console.log("Error fetching total count");
        setCounter(count?.data?.counter || 0);
      })
      .catch (error => {
        console.error("Error fetching total count:", error);
      })
      fetchTotalProfileCount().then((arr)=>{
        if(!arr) return console.log("Error fetching total count");
        setTotalCount(arr[0]);
        setModUID(arr[1]);
        console.log("Count:", arr[0], "Mod UID:", arr[1]);
      })
      .catch (error => {
        console.error("Error fetching total count:", error);
      })
    };
    getCount();
  }, []);
  const name = ADMIN_UID_TO_NAME[modUID];

  const handleCopyUid = () => {
    navigator.clipboard.writeText(modUID);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  

  return (
    <div className="header-left-display">
      <Avatar
        sx={{
          bgcolor: "white",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "15px",
          minWidth: "50px",
          color: "black"
        }}
        variant="square"
      >
        {counter}
      </Avatar>
      <span>{totalCount}</span>
      <span> •</span>
      <Tooltip title={modUID} placement="bottom-start">
        <Button onClick={handleCopyUid} sx={{
          margin:0,
          padding:0,
          width: "fit-content",
          color: "black"
        }}>{name}</Button>
      </Tooltip>
      {/* ******************************* */}
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Mod UID copied to clipboard!
        </Alert>
      </Snackbar>
    </div>
  );
}
