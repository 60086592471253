import { createSlice } from "@reduxjs/toolkit";
import { deepMerge } from "./utils";

export const chatSlice = createSlice({
  name: "chats",
  initialState: {
    posts: [[]],
    loading: true,
    activePost: -1,
    page: 1,
    startAfter: false,
    showFullScreen: false,
    disableNext: false,
    detailsLoading: false,
    showUiLoading: false,
    isHistory: false,
    showPopup: { show: false, ID: undefined },
    admins: [],
    adminFilter: "",
    reportedFilter: "",
    actionTypeOption: 0,
    range: "",
    showAgeSuccess: false,
    preFetchedFirstPostPages: [],
  },
  reducers: {
    setPosts: (state, action) => {
      state.posts[action.payload.page] = action.payload.posts;
      state.loading = false;
    },
    setActivePost: (state, action) => {
      if (action.payload >= -1) state.activePost = action.payload;
    },
    setStartAfter: (state, action) => {
      state.startAfter = action.payload;
    },
    removePost: (state, action) => {
      if (state.posts[state.page])
        state.posts[state.page].forEach((post, i) => {
          if (post.id === action.payload.id)
            state.posts[state.page].splice(i, 1);
        });
    },
    nextPost: (state) => {
      state.activePost++;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    exitFullScreen: (state) => {
      state.activePost = -1;
    },
    setDisableNext: (state, action) => {
      state.disableNext = action.payload;
    },
    updatePost: (state, action) => {
      const { index, data } = action.payload;
      console.log("Updating post with data:", data);
      const post = state.posts[state.page][index];
      if (post) {
        deepMerge(post, data);
      } else {
        console.warn(
          `Post at index ${index} does not exist on page ${state.page}.`
        );
      }
    },
    markPostAsProcessingModeration: (state, action) => {
      const { index, page } = action.payload;
      if (state.posts[page][index]) {
        state.posts[page][index].isProcessingModeration = true;
        console.log("mark post as moderated:", state.posts[page][index]);
      }
    },
    markPostAsModerated: (state, action) => {
      const { index, page } = action.payload;
      if (state.posts[page][index]) {
        state.posts[page][index].isProcessingModeration = false;
        state.posts[page][index].isModerated = true;
        console.log("mark post as moderated:", state.posts[page][index]);
      }
    },
    markPostAsAgeModerated: (state, action) => {
      const index = action.payload;
      if (state.posts[state.page][index]) {
        state.posts[state.page][index].isAgeModerated = true;
        console.log(
          "marked post as ageModerated:",
          state.posts[state.page][index]
        );
      }
    },
    setDetailsLoading: (state, action) => {
      state.detailsLoading = action.payload;
    },
    setShowUiLoading: (state, action) => {
      state.showUiLoading = action.payload;
    },
    setIsHistory: (state, action) => {
      state.isHistory = action.payload;
    },
    clearPosts: (state) => {
      state.posts = [[]];
      state.page = 1;
      state.activePost = -1;
      state.startAfter = false;
      state.disableNext = false;
      state.detailsLoading = false;
    },
    setShowPopup: (state, action) => {
      state.showPopup = action.payload;
    },
    setAdmins: (state, action) => {
      state.admins = action.payload;
    },
    setAdminFilter: (state, action) => {
      state.adminFilter = action.payload;
    },
    setReportedFilter: (state, action) => {
      state.reportedFilter = action.payload;
    },
    setActionTypeOption: (state, action) => {
      state.actionTypeOption = action.payload;
    },
    setRange: (state, action) => {
      state.range = action.payload;
    },
    setShowAgeSuccess: (state, action) => {
      state.showAgeSuccess = action.payload;
    },
    setPreFetchedFirstPostPages: (state, action) => {
      state.preFetchedFirstPostPages.push(action.payload);
    },
    clearPreFetchedArray: (state, action) => {
      state.preFetchedFirstPostPages = [];
    },
  },
});

export const selectValidModeratedCase = (state) => {
  const currentPage = state.chats.page;
  const activePost = state.chats.activePost;
  // console.log("selectValidModeratedCase - currentPage:", currentPage, "activePost:", activePost);

  const postsOnPage = state.chats.posts[currentPage] || [];
  // console.log("selectValidModeratedCase - postsOnPage:", postsOnPage);

  return postsOnPage.some((post, index) => {
    if (index === activePost) {
      // console.log(`Skipping active post at index ${index}`);
      return false;
    }
    if (post.openCase && Array.isArray(post.openCase) && post.openCase.length > 1) {
      const expectedValue = post.openCase.length - 1;
      // Treat undefined moderatedCases as 0.
      const moderatedCases = post.moderatedCases ?? 0;
      const condition = expectedValue > moderatedCases;
      // console.log(
      //   `Post index ${index}: openCase length = ${post.openCase.length}, moderatedCases = ${moderatedCases}, expected moderatedCases = ${expectedValue}, condition met: ${condition}`
      // );
      return condition;
    }
    // console.log(`Post index ${index}: openCase is missing or its length is 1 or less.`);
    return false;
  });
};



export const {
  setActivePost,
  nextPost,
  setPosts,
  removePost,
  changePage,
  setStartAfter,
  setLoading,
  exitFullScreen,
  setDisableNext,
  updatePost,
  markPostAsModerated,
  markPostAsProcessingModeration,
  markPostAsAgeModerated,
  setDetailsLoading,
  setShowUiLoading,
  setIsHistory,
  clearPosts,
  setShowPopup,
  setAdmins,
  setAdminFilter,
  setReportedFilter,
  setActionTypeOption,
  setRange,
  setShowAgeSuccess,
  setPreFetchedFirstPostPages,
  clearPreFetchedArray,
} = chatSlice.actions;
export const selectPosts = (state) => state.chats.posts;
export const selectNumberOfPages = (state) => state.chats.posts.length - 1;
export const selectPage = (state) => state.chats.page;
export const selectLoading = (state) => state.chats.loading;
export const selectActivePost = (state) => state.chats.activePost;
export const selectStartAfter = (state) => state.chats.startAfter;
export const selectShowFullScreen = (state) => state.chats.showFullScreen;
export const selectDisableNext = (state) => state.chats.disableNext;
export const selectDetailsLoading = (state) => state.chats.detailsLoading;
export const selectShowUiLoading = (state) => state.chats.showUiLoading;
export const selectIsHistory = (state) => state.chats.isHistory;
export const selectShowPopup = (state) => state.chats.showPopup;
export const selectReportedFilter = (state) => state.chats.reportedFilter;
export const selectAdmins = (state) => state.chats.admins;
export const selectAdminFilter = (state) => state.chats.adminFilter;
export const selectShowAgeSuccess = (state) => state.chats.showAgeSuccess;
export const selectActionTypeOption = (state) => state.chats.actionTypeOption;
export const selectRange = (state) => state.chats.range;
export const selectPreFetchedFirstPostPages = (state) =>
  state.chats.preFetchedFirstPostPages;

export default chatSlice.reducer;
