import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/auth/userSlice.ts";
import postsReducer from "../features/posts/postsSlice";
import profileReducer from "../features/profile/postsSlice.js";
import chatReducer from "../features/new_chat/chatSlice.js";

export default configureStore({
  reducer: {
    user: userReducer,
    posts: postsReducer,
    profile: profileReducer,
    chats: chatReducer
  },
});
