// src/features/new_chat/hooks/useChats.js
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePage,
  exitFullScreen,
  markPostAsModerated,
  selectAdminFilter,
  selectDisableNext,
  selectIsHistory,
  selectLoading,
  selectNumberOfPages,
  selectPage,
  selectReportedFilter,
  selectShowPopup,
  selectStartAfter,
  setActivePost,
  setAdmins,
  setDisableNext,
  setLoading,
  setDetailsLoading,
  setPosts,
  setShowPopup,
  setStartAfter,
  updatePost,
  setShowAgeSuccess,
  selectDetailsLoading,
  setShowUiLoading,
  markPostAsProcessingModeration,
  selectRange,
  selectActionTypeOption,
  markPostAsAgeModerated,
  selectValidModeratedCase,
} from "./chatSlice";
import { db } from "../../app/firebase";
import {
  checkOpenCasesAPI,
  fetchAdditionalData,
  fetchAdditionalDataFromOpenCase,
  handleAge,
  handleModerationAPI,
  hasLotOfUndefinedValues,
  loadAdminsAPI,
  mapHistoryPostToCommonStructure,
  preFetchImages,
} from "./utils";
import { selectUser } from "../auth/userSlice";
import { cloneDeep, fromPairs } from "lodash";

const useChats = (hoveredComponent, leftRef, middleRef, rightRef, setShowUrgentChatBanner) => {
  const dispatch = useDispatch();

  const { posts, page, activePost } = useSelector((state) => state.chats);
  const currentPage = useSelector(selectPage);
  const startAfter = useSelector(selectStartAfter);
  const numberOfPages = useSelector(selectNumberOfPages);
  const disableNext = useSelector(selectDisableNext);
  const mod_uid = useSelector(selectUser);
  const isHistory = useSelector(selectIsHistory);
  const showPopup = useSelector(selectShowPopup);
  const detailsLoading = useSelector(selectDetailsLoading);
  const reportedFilter = useSelector(selectReportedFilter);
  const actionTypeOption = useSelector(selectActionTypeOption);
  const adminFilter = useSelector(selectAdminFilter);
  const range = useSelector(selectRange);
  const validModeratedCase = useSelector(selectValidModeratedCase);

  // ****  auth state  ****
  const { uid } = useSelector(selectUser);

  const fetchChats = useCallback(
    async (startAfterDoc = null, currentPage = 1) => {
      try {
        let postsRef = db
          .collection("chat_report")
          .where("open_report_counter", ">=", 1)
          .orderBy("open_report_counter", "desc")
          .orderBy("last_report_date", "desc")
          .limit(20);

        if (startAfterDoc) {
          postsRef = postsRef.startAfter(startAfterDoc);
        }

        const snapshot = await postsRef.get();

        if (snapshot.empty) {
          console.log("No more chats to fetch.");
          dispatch(setLoading(false));
          return;
        }

        const fetchedPosts = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        dispatch(setPosts({ posts: fetchedPosts, page: currentPage }));
        dispatch(setStartAfter(snapshot.docs[snapshot.docs.length - 1]));
        dispatch(setLoading(false));
        if (snapshot.docs.length < 20) {
          dispatch(setDisableNext(true));
        }
      } catch (error) {
        console.error("Error fetching chats:", error);
        dispatch(setLoading(false));
      }
    },
    [dispatch]
  );

  const fetchHistoryChats = useCallback(
    async (
      startAfterDoc = null,
      currentPage = 1,
      userID = null,
      uid = null,
      range = null,
      action = 0
    ) => {
      try {
        if (range) {
          range = new Date(range);
        }
        let postsRef = db.collection("chat_history");
        console.log("Fetch History with userID", userID || reportedFilter);

        if (userID || reportedFilter) {
          postsRef = postsRef.where(
            "reportedUser.userID",
            "==",
            userID || reportedFilter
          );
          console.log("Fetch History with userID", userID || reportedFilter);
        }

        if (uid && uid != "All") {
          postsRef = postsRef.where("mod_uid", "==", uid);
          console.log("getposts - chatHistory with mod_uid", uid);
        }
        if (action) {
          if (action === 1) {
            postsRef = postsRef.where("mod_res", "==", 1);
            console.log("Checking for Type Safe");
          } else if (action === 2) {
            postsRef = postsRef.where("mod_res", "==", 2);
            console.log("Checking for Type Notify");
          } else if (action === 3) {
            postsRef = postsRef.where("mod_res", "==", 2);
            postsRef = postsRef.where("mod_reason", "==", "selling");
            console.log("Checking for Type selling");
          } else {
            postsRef = postsRef.where("mod_res", "==", 3);
          }

          console.log("fetchHistoryChats with action filter:", action);
        }

        if (range) {
          postsRef = postsRef.where("date", "<=", range);
          console.log(userID, "getposts - chatHistory with range");
        }

        postsRef = postsRef.orderBy("date", "desc").limit(20);
        if (startAfterDoc) {
          postsRef = postsRef.startAfter(startAfterDoc);
        }

        const snapshot = await postsRef.get();

        if (snapshot.empty) {
          if (currentPage === 1) {
            dispatch(setPosts({ posts: [], page: currentPage }));
          }
          console.log("No more chats to fetch.");
          dispatch(setLoading(false));
          return;
        }
        console.log(
          "Fetched History Post BEFORE Map:",
          snapshot.docs[0].data()
        );
        const fetchedPosts = snapshot.docs.map((doc) =>
          mapHistoryPostToCommonStructure({ ...doc.data(), id: doc.id })
        );
        console.log("Fetched History Post AFTER Map:", fetchedPosts[0]);

        // console.log("first doc after map", fetchedPosts[0]);

        dispatch(setPosts({ posts: fetchedPosts, page: currentPage }));
        dispatch(setStartAfter(snapshot.docs[snapshot.docs.length - 1]));
        dispatch(setLoading(false));
        if (snapshot.docs.length < 20) {
          dispatch(setDisableNext(true));
        }
      } catch (error) {
        console.error("Error fetching chats:", error);
        dispatch(setLoading(false));
      }
    },
    [dispatch, reportedFilter]
  );

  const fetchNextChats = useCallback(async () => {
    try {
      if (disableNext) return;
      if (currentPage < numberOfPages) {
        console.log("going to next page");
        dispatch(setLoading(true));
        dispatch(changePage(currentPage + 1));
        await new Promise((resolve) => setTimeout(resolve, 150));
        dispatch(setLoading(false));
        return;
      }
      dispatch(setLoading(true));
      !isHistory
        ? await fetchChats(startAfter, currentPage + 1)
        : await fetchHistoryChats(
          startAfter,
          currentPage + 1,
          reportedFilter,
          adminFilter,
          range,
          actionTypeOption
        );
      console.log("changing page from", currentPage);
      dispatch(changePage(currentPage + 1));
    } catch (error) {
      console.error("Error fetching next chats:", error);
    }
  }, [
    disableNext,
    currentPage,
    numberOfPages,
    dispatch,
    isHistory,
    fetchChats,
    startAfter,
    fetchHistoryChats,
    reportedFilter,
    adminFilter,
  ]);

  const fetchChatDetails = useCallback(
    async (uid, postIndex, preFetching = false) => {
      try {
        if (preFetching) {
          dispatch(
            updatePost({ index: postIndex, data: { startedFetching: true } })
          );
        } else {
          console.log("DetailsLoading is true 1");
          dispatch(setDetailsLoading(true));
        }
        const additionalData = await fetchAdditionalData(uid);
        const images = [
          additionalData?.reportedVerifyImage,
          additionalData?.reportedProfileImage,
          additionalData?.reportingProfileImage,
          additionalData?.reportingVerifyImage,
        ];
        preFetchImages(images);
        console.log("Additional data fetched:", additionalData);
        dispatch(updatePost({ index: postIndex, data: additionalData }));
        if (detailsLoading) {
          console.log("DetailsLoading is false 1");
          dispatch(setDetailsLoading(false));
        }
        return true;
      } catch (error) {
        console.error("Error fetching chat details:", error);
        return false;
      }
    },
    [dispatch, updatePost, activePost, detailsLoading]
  );

  const handleModeration = useCallback(
    async (index, moderationData) => {
      try {
        // Call the moderation API
        const action = moderationData["action"];
        let data = cloneDeep(moderationData["data"]);
        const promises = [];
        if (action === "ban") {
          const reason = prompt("Reason for Ban");
          if (reason === null || reason === "") {
            return;
          }
          data["reason"] = reason;
        }

        const moderationPromise = handleModerationAPI(action, data, mod_uid);
        promises.push(moderationPromise);

        console.log("Data being moderated:", data);

        const case_to_moderate = data?.moderatedCases !== undefined
          ? data?.moderatedCases + 1
          : 0;
        console.log("moderationCase", data?.moderatedCases);
        console.log("case_to_moderate", case_to_moderate);
        const hasMoreCases =
          (data?.openCase?.length - 1) > case_to_moderate &&
          data?.openCase?.length > 1;
        if (hasMoreCases) {
          console.log("User has more open cases.");
          // Add the conditional promise to the array
          dispatch(setDetailsLoading(true));
          dispatch(setShowUiLoading(true));
          const additionalDataPromise = fetchAdditionalDataFromOpenCase(
            data?.openCase,
            case_to_moderate + 1
          );
          promises.push(additionalDataPromise);
        }
        if (!hasMoreCases) {
          dispatch(markPostAsProcessingModeration({ index, page }));
          dispatch(setShowUiLoading(true));
          console.log("showUiLoading is true 2");
          goToNextPost();
          console.log("went to next post");
        }
        // Wait for all promises to resolve
        const [moderation_result, additionalData] = await Promise.all(promises);

        if (hasMoreCases) {
          dispatch(setDetailsLoading(false));
          dispatch(setShowUiLoading(false));
        }
        if (!moderation_result) {
          alert(
            `Error calling Moderation API For Previous Post (Its not moderated)`
          );
          dispatch(setShowUiLoading(false));
          console.log("showUiLoading is false 1");
          return;
        }

        if (hasMoreCases && !hasLotOfUndefinedValues(additionalData)) {
          dispatch(
            updatePost({
              index,
              data: { ...additionalData, moderatedCases: case_to_moderate },
            })
          );
          dispatch(setDetailsLoading(false));
          dispatch(setShowUiLoading(false));
          console.log("showUiLoading is false 22");
        } else {
          if (case_to_moderate > 0 && !validModeratedCase) {
            console.log("setting banner to true from case to moderate > 1 and valideModeratedCase is false");
            setShowUrgentChatBanner(true)
          }
          goToNextPost();
          dispatch(setShowUiLoading(false));
          console.log("showUiLoading is false 3");
          dispatch(markPostAsModerated({ index, page }));
        }
      } catch (error) {
        console.error("Error during moderation:", error);
      }
    },
    [dispatch, posts, currentPage, activePost, validModeratedCase]
  );

  const handleAgeModeration = useCallback(
    async (data, index) => {
      try {
        const result = await handleAge(data);
        if (result) {
          dispatch(markPostAsAgeModerated(index));
          dispatch(setShowAgeSuccess(true));
        }
      } catch (error) {
        console.error("Error calling handleAge:", error);
      }
    },
    [dispatch, handleAge]
  );

  const goToNextPost = useCallback(async () => {
    if (activePost === null) return;

    const currentPagePosts = posts[page] || [];
    const isLastPostOnPage = activePost === currentPagePosts.length - 1;
    const moreLoadedPages = posts?.length > page + 1;

    if (!isLastPostOnPage) {
      dispatch(setActivePost(activePost + 1));
      console.log("Going to next post without fetch");
    } else {
      if (!moreLoadedPages) {
        if (!disableNext) {
          dispatch(setDetailsLoading(true));
          await fetchNextChats();
          await new Promise((resolve) => setTimeout(resolve, isHistory ? 600 : 1800));
          dispatch(setDetailsLoading(false));
          dispatch(setActivePost(0));
        }
        else {
          dispatch(setActivePost(-1));
        }
      } else {
        dispatch(changePage(page + 1));
        dispatch(setActivePost(0));
      }
    }
  }, [activePost, dispatch, fetchNextChats, page, posts]);

  const goToPreviousPost = useCallback(() => {
    if (activePost === null) return;

    if (activePost > 0) {
      dispatch(setActivePost(activePost - 1));
      if (detailsLoading) {
        dispatch(setDetailsLoading(false));
        console.log("DetailsLoading is false 2");
      }
    } else if (page > 1) {
      const previousPage = page - 1;
      const previousPagePosts = posts[previousPage] || [];
      dispatch(changePage(previousPage));
      dispatch(setActivePost(previousPagePosts.length - 1));
      if (detailsLoading) {
        dispatch(setDetailsLoading(false));
        console.log("DetailsLoading is false 3");
      }
    }
  }, [activePost, dispatch, page, posts]);

  const handleKeyUp = useCallback(
    (event) => {
      if (event.ctrlKey || event.metaKey) {
        return;
      }

      const key = event.key.toLowerCase();

      if (hoveredComponent) {
        if (key === "arrowup") {
          // Scroll up the hovered component
          if (hoveredComponent === "left" && leftRef.current) {
            leftRef.current.scrollBy({ top: -100, behavior: "smooth" });
            event.preventDefault(); // Prevent default scrolling behavior
          } else if (hoveredComponent === "middle" && middleRef.current) {
            middleRef.current.scrollBy({ top: -100, behavior: "smooth" });
            event.preventDefault();
          } else if (hoveredComponent === "right" && rightRef.current) {
            rightRef.current.scrollBy({ top: -100, behavior: "smooth" });
            event.preventDefault();
          }
          return; // Exit to prevent further handling
        } else if (key === "arrowdown") {
          // Scroll down the hovered component
          if (hoveredComponent === "left" && leftRef.current) {
            leftRef.current.scrollBy({ top: 100, behavior: "smooth" });
            event.preventDefault();
          } else if (hoveredComponent === "middle" && middleRef.current) {
            middleRef.current.scrollBy({ top: 100, behavior: "smooth" });
            event.preventDefault();
          } else if (hoveredComponent === "right" && rightRef.current) {
            rightRef.current.scrollBy({ top: 100, behavior: "smooth" });
            event.preventDefault();
          }
          return; // Exit to prevent further handling
        }
      }

      switch (key) {
        case "escape":
          if (showPopup?.show) {
            dispatch(setShowPopup({ show: false, ID: undefined }));
          } else {
            dispatch(exitFullScreen());
          }
          break;
        case "arrowright":
          goToNextPost();
          console.log("Key ArrowRight pressed");
          break;
        case "arrowleft":
          goToPreviousPost();
          console.log("Key ArrowLeft pressed");
          break;
        case "f":
          console.log("Key f pressed");
          if (isHistory) break;
          dispatch(setDetailsLoading(true));
          console.log("DetailsLoading is true 4");
          handleModeration(activePost, {
            action: "notify",
            data: posts[page][activePost],
          });
          break;
        case "j":
          if (isHistory) break;
          dispatch(setDetailsLoading(true));
          console.log("DetailsLoading is true 5");
          handleModeration(activePost, {
            action: "keep",
            data: posts[page][activePost],
          });
          console.log("Key J pressed");
          break;
        case "i":
          if (isHistory) break;
          handleModeration(activePost, {
            action: "ban",
            data: posts[page][activePost],
          });
          console.log("Key I pressed");
          break;
        case "z":
          if (isHistory) break;
          dispatch(setDetailsLoading(true));
          console.log("DetailsLoading is true 6");
          handleModeration(activePost, {
            action: "selling",
            data: posts[page][activePost],
          });
          console.log("Key Z pressed");
          break;
        case "a":
          if (isHistory) break;
          handleAgeModeration(posts[page][activePost], activePost);
          console.log("Key F pressed");
          break;
        case "q":
          if (!showPopup?.show) {
            navigator.clipboard
              .writeText(posts[page][activePost]?.reportedUserID)
              .then(() => {
                console.log("Text copied to clipboard");
              })
              .catch((err) => {
                console.error("Failed to copy text: ", err);
              });
            dispatch(
              setShowPopup({
                show: true,
                ID: posts[page][activePost]?.reportedUserID,
              })
            );
          }
          break;
        case "w":
          if (!showPopup?.show) {
            navigator.clipboard
              .writeText(posts[page][activePost]?.reportingUserID)
              .then(() => {
                console.log("Text copied to clipboard");
              })
              .catch((err) => {
                console.error("Failed to copy text: ", err);
              });
            dispatch(
              setShowPopup({
                show: true,
                ID: posts[page][activePost]?.reportingUserID,
              })
            );
          }
          break;
        default:
          break;
      }
    },
    [
      hoveredComponent,
      leftRef,
      middleRef,
      rightRef,
      posts,
      activePost,
      page,
      dispatch,
      goToNextPost,
      goToPreviousPost,
      showPopup,
    ]
  );

  const loadAdmins = useCallback(async () => {
    try {
      const admins = await loadAdminsAPI(uid);
      dispatch(setAdmins(admins));
    } catch (error) {
      console.error("Error loading admins:", error);
    }
  }, [dispatch]);

  return {
    fetchChats,
    fetchHistoryChats,
    fetchNextChats,
    goToNextPost,
    goToPreviousPost,
    handleKeyUp,
    fetchChatDetails,
    handleModeration,
    loadAdmins,
  };
};

export default useChats;
