import "./posts.style.scss";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef, useCallback } from "react";
import { db, sendNotification, ban_user_mod, view_verif_img, banned_moderation } from "../../app/firebase";

import { setActivePost, selectActivePost } from "./postsSlice";

import dayjs from "dayjs";
import { selectUser } from "../auth/userSlice";
import { Button } from "primereact/button";
import SearchUser from "../search/SearchUser";

export default function Post(props) {
    const [reportedImg, setReportedImg] = useState('')
    const [reportingImg, setReportingImg] = useState('')
    const [ipStatus, setIpStatus] = useState('')
    const [ipThreat, setIpThreat] = useState('')
    const [isloading, setIsLoading] = useState(true)
    const scrollableRef = useRef(null);

    const spaceRef = useRef();
    const handleCopy = (id) => {
        navigator.clipboard.writeText(id).then(() => {
            console.log("Text copied successfully.");
        })
    }

    const handleKeyDown = (e) => {

        if (e.key === " ") {
            // spaceRef?.current?.scrollIntoView({ behavior: 'smooth' });
            // console.log("Scroll", e.key);
            // window.scrollBy({
            //     top: 0,
            //     left: 0,
            //     behavior: 'smooth'
            // });
            e.preventDefault();  // Prevent the page from scrolling
            if (scrollableRef.current) {
                // Scroll the scrollable component
                scrollableRef.current.scrollBy({ top: 200, behavior: 'smooth' });
            }
        }
        // if (e.key === "w" && isActive) {
        //     console.log("first")
        //     handleCopy(props?.roomIdData.uid_reporting)
        // }
    }

    // function handleKeyUp(e){
    //     if (e.key === " " && e.target === document.body) {
    //         // spaceRef?.current?.scrollIntoView({ behavior: 'smooth' });
    //         console.log("Scroll", e.key);
    //         window.scrollBy({
    //             top: 0,
    //             left: 0,
    //             behavior: 'smooth'
    //         });
    //     }
    //     if (e.key === "w") {
    //         console.log("first")
    //         handleCopy( props?.roomIdData.uid_reporting)
    //       }
    // }

    // window.addEventListener('keydown', (e) => {  
    //     if (e.keyCode === 32 && e.target === document.body) {  
    //       e.preventDefault();  
    //     }  
    //   });

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return function () {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const [currentUserDetails, setCurrentUserDetails] = useState("")

    const [utcTime, setUtcTime] = useState('');

    useEffect(() => {
        function convertSecondsTo(seconds) {
            const utcDate = new Date(seconds * 1000); // Convert seconds to milliseconds for Date object
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            };
    
            return utcDate.toLocaleString(undefined, options); // Formats according to local timezone
        }
    
        const seconds = props?.datePost;
        if (seconds) {
            const utcTimeString = convertSecondsTo(seconds);
            setUtcTime(utcTimeString);
        }
    }, [props?.datePost]);


    const { isPictureFullscreen, setIsPictureFullscreen } = props;
    const activePost = useSelector(selectActivePost);
    const dispatch = useDispatch();

    let user = useSelector(selectUser);
    const isActive = activePost === props?.postId;
    const isBoosted = props?.boost_request && props?.reported;
    const isSafelyBoosted = props?.boost_safe;



    function handleSelect() {
        if (!isActive) dispatch(setActivePost(props?.postId));
        // console.log(props?.postId, props?.uid, "props?.postId");
        // console.log("props?.uid", props?.uid);
    }

    function unselect() {
        dispatch(setActivePost(-1));
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function renderModStatus(mod_res, mod_reason) {
        if (mod_res === 1) {
            return "Safe";
        }
        else if (mod_res === 2 && mod_reason === "selling") {
            return "Notify • Selling";
        }
        else if (mod_res === 2) {
            return "Notify";
        }
        else {
            return "Ban";
        }
    }

    // async function preLoadChat(userID, k) {
    //     let k;
    //     try {
    //         if (!k) {
    //             k = 0
    //         }

    //         const getRoomIdData = await db.collection("chat_report").doc(userID).collection("report_list")
    //             .where("need_moderation", "==", true)
    //             .orderBy("date", "desc").limit(20).get()

    //         let posts = []
    //         // let posts
    //         getRoomIdData?.forEach((item, i) => {
    //             let post = { ...item.data(), id: item.id };
    //             posts.push(post);
    //             // posts = post;
    //         })

    //         // Get Chat Base on roomId
    //         let chatPosts = await getChatData(posts[k]?.roomID)


    //         // Reported User query

    //         let reportedUserData = await getReportedUser(posts[k]?.uid_reported)


    //         // Reporting User query

    //         let reportingUserData = await getReportingUser(posts[k]?.uid_reporting)



    //         // Open Cases query

    //         let openCasePosts = await getOpenCaseUser(posts[k]?.uid_reported)

    //         // Close Cases query

    //         let closeCasePosts = await getCloseCaseUser(posts[k]?.uid_reported)
    //     }
    //     catch (error) {
    //         console.log(error, "error in preLoadChat");
    //     }
    // }

    useEffect(() => {
        if (isActive && !props?.type) {
            props?.getRoomId(props?.uid || props?.id, null)
            // console.log(props, "Currentid-------chat-------", isActive)
            props?.setId(props?.uid || props?.id)
        }
    }, [isActive]);

    useEffect(() => {
        if (props?.mod_uid && isActive) {
            props?.setId2(props?.reportingUser?.userID || props?.reported_user?.uid || "")
            props?.setId(props?.reportedUser?.userID || props?.reported_user?.uid || "")
        }

    }, [isActive])

    // useEffect(()=>{
    //     console.log(props, "reporteduser")
    //     console.log(props?.reportedUser?.first_profile_url, "reporteduser")
    //     console.log(props?.reportingUser?.first_profile_url, "reporteduser")
    //     console.log(props?.roomIdData?.reportedProfileImage, "reporteduser")
    // },[props?.reportedUser])

    // useEffect(() => {
    //     if (props?.mod_uid && isActive) {
    //         props?.setId(props?.reportedUser.userID)
    //     }
    // }, [isActive])
    // console.log(props, "isActive");
    let reported = props?.roomIdData.openCase && props?.roomIdData.openCase.length && props?.roomIdData.openCase[0].uid_reported
    let reporting = props?.roomIdData.openCase && props?.roomIdData.openCase.length && props?.roomIdData.openCase[0].uid_reporting
    useEffect(() => {
        const fetchImages = async () => {
            try {
                let reportedVerifImage = await view_verif_img({ uid: reported })
                let reportingVerifImage = await view_verif_img({ uid: reporting })
                setReportedImg(reportedVerifImage.data.url)
                setReportingImg(reportingVerifImage.data.url)
                setIsLoading(false)
                // console.log("props?.roomIdData", props?.roomIdData)
            } catch (error) {
                console.log(error, "error")
                setIsLoading(false)
            }

        }
        const getIpScore = async () => {
            await db
                .collection("banned_moderation")
                .doc(reported)
                .get()
                .then((banInfo) => {
                    // switch (banInfo.data().ip_score) {
                    //     case 100:
                    //         setIpStatus("Safe")
                    //         break;
                    //     case 23:
                    //         setIpStatus("VPN")
                    //         break;
                    //     case 0:
                    //         setIpStatus("🔴 Banned Country")
                    //         break;
                    //     default:
                    //         break;
                    // }
                    console.log("banInfo", banInfo.data())
                    if (banInfo.data().banned_ip_country === true) return setIpStatus("🔴 Banned Country")
                    if (banInfo.data().ip_score === 0) {
                        setIpStatus(0)
                    }
                    else setIpStatus(banInfo.data().ip_score)
                    setIpThreat(banInfo.data().ip_threat)
                })
                .catch(() => {
                    return "Error loading banned info.";
                });
        }
        if (isActive && reported || reporting) {
            getIpScore();
            fetchImages();
        }
        else if (isActive && reported === 0 || reporting === 0) {
            setIsLoading(false)
        }

    }, [reported, reporting, isActive, isloading])

    if (isActive) {
        return (
            <div className={`post ${isActive && "fullscreen fullscreen-white"}`} >

                {!props?.uid ? (
                    <>
                        {
                            props?.type ? (
                                <>
                                    {!isPictureFullscreen && (
                                        <div onClick={unselect} className="close">
                                            Close
                                        </div>
                                    )}
                                    <div className="description description-add">

                                        <h3>Reported User</h3>
                                        <p>
                                            <b>Username: {props?.reported_user?.name || ""} </b>
                                        </p>

                                        <p className="user-data-id"> userID: {props?.reported_user?.userID || ""} </p>

                                        <p> age: {props?.reported_user?.age || ""} </p>
                                        <p> country: {props?.reported_user?.country || ""} </p>
                                        <p> gender: {props?.reported_user?.gender || ""} </p>
                                        <p> <img className="w-100" src={props?.reported_user?.imgOne || ""} alt="image not available"></img>  </p>
                                        <p> <img className="w-100" src={props?.reported_user?.imgVerif || ""} alt="image not available"></img>  </p>
                                    </div>

                                    <div className="picture">
                                        <div>
                                            <div className="report-text">
                                                <p>Reported for: {props?.report_reason || ''} </p>
                                            </div>
                                            <div ref={spaceRef} />

                                            <div className="chat-div" ref={scrollableRef}>
                                                {
                                                    props?.messages?.map((x) => {
                                                        return (
                                                            x.userID === props?.reported_user.userID ? (
                                                                <>
                                                                    <div className="chat-width">
                                                                        <div className="chater">
                                                                            <p>{x.text}</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        x?.text === "Replied to a Post"
                                                                        &&
                                                                        <div className="chat-width">
                                                                            <div className="chater">
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    flexDirection: 'column',
                                                                                    justifyContent: 'center',
                                                                                    maxHeight: "300px",
                                                                                    maxWidth: "150px"
                                                                                }}>
                                                                                    <p style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'flex-start',
                                                                                        width: '100%'
                                                                                    }}>Caption: {x?.post_info?.caption}</p>
                                                                                    <div style={{
                                                                                        height: "200px",
                                                                                        width: "100px",
                                                                                        objectFit: 'contain',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center'
                                                                                    }}>
                                                                                        <img src={x?.post_info?.image_url} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="chat-width chat-width2">
                                                                        <div className="chater chater2">
                                                                            <p>{x.text}</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        x?.text === "Replied to a Post"
                                                                        &&
                                                                        <div className="chat-width chat-width2">
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                flexDirection: 'column',
                                                                                justifyContent: 'center',
                                                                                maxHeight: "300px",
                                                                                maxWidth: "150px"
                                                                            }}>
                                                                                <p style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'flex-start',
                                                                                    width: '100%'
                                                                                }}>Caption: {x?.post_info?.caption}</p>
                                                                                <div style={{
                                                                                    height: "200px",
                                                                                    width: "100px",
                                                                                    objectFit: 'contain',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center'
                                                                                }}>
                                                                                    <img src={x?.post_info?.image_url} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )
                                                        )
                                                    })
                                                }
                                            </div>
                                            {/* <div className="response">
                                            <strong style={{ fontSize: "20px" }}>Res:
                                                {' '}
                                                {renderModStatus(props?.mod_res, props?.mod_reason)}
                                            </strong><br />
                                            <p style={{ marginTop: "3px" }}>
                                                Comment : {props?.mod_comment === "No Reason" ? (
                                                    <span>ø</span>
                                                ) : (
                                                    <span>{props?.mod_comment || "-"}</span>
                                                )}
                                            </p>
                                            <p>
                                                Mod: {props?.mod_uid}
                                            </p>
                                            <p>Date: {props?.datePost ? utcTime : ""}</p>
                                        </div> */}
                                        </div>
                                    </div>

                                    <div className="description description-right">
                                        {/* <small className="time">{dayjs.unix(props?.datePost).fromNow()}</small> */}
                                        <h3>Reporting User</h3>
                                        <p>
                                            <b>Username: {props?.reporting_user?.name || ""} </b>
                                        </p>
                                        <p className="user-data-id"> userID: {props?.reporting_user?.userID || ""} </p>

                                        <p> age: {props?.reporting_user?.age || ""}  </p>
                                        <p> country: {props?.reporting_user?.country || ""} </p>
                                        <p> gender: {props?.reporting_user?.gender ?? ""} </p>
                                        <p> <img className="w-100" src={props?.reporting_user?.imgOne || ""} alt="image not available"></img>  </p>
                                        <p> <img className="w-100" src={props?.reporting_user?.imgVerif || ""} alt="image not available"></img>  </p>
                                    </div>

                                    {!props?.showDropdown && props?.controls}
                                </>
                            ) : (
                                <>
                                    {!isPictureFullscreen && (
                                        <div onClick={unselect} className="close">
                                            Close
                                        </div>
                                    )}
                                    <div className="description description-add">

                                        <h3>Reported User</h3>
                                        <p>
                                            <b>Username: {props?.reportedUser?.username || ""} </b>
                                        </p>

                                        <p className="user-data-id"> userID: {props?.reportedUser?.userID || ""} </p>

                                        <p> age: {props?.reportedUser?.age || ""} </p>
                                        <p> country: {props?.reportedUser?.country} </p>
                                        <p> gender: {props?.reportedUser?.gender} </p>
                                        <p> ProfileImage: <img className="img-circle" src={props?.reportedUser?.first_profile_url} alt="image not available"></img>  </p>

                                        <div className="w-100">
                                            <h4>{props?.roomIdData?.openCase?.length >= 2 && "🔴"} Open Cases ({props?.roomIdData?.openCase?.length})</h4>
                                            {
                                                props?.roomIdData?.openCase?.map((x) => {
                                                    return (
                                                        <>
                                                            <div className="table-chat">
                                                                <div className="user-get">
                                                                    <div className="user-get-text">
                                                                        <h4>{x?.username_reporting}</h4>
                                                                        {/* <p>{props?.roomIdData.openCasePostsSinceDate}</p> */}
                                                                        <p>{dayjs.unix(x?.date?.seconds).fromNow()}</p>
                                                                    </div>
                                                                    <div className="user-get-text">
                                                                        <p className="w-fix">Text</p>
                                                                        <p className="w-fix">{x?.reason}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }

                                        </div>

                                        <div className="w-100 mt-4">
                                            <h4>Close Cases ({props?.roomIdData?.closeCase?.length})</h4>
                                            {
                                                props?.roomIdData?.closeCase?.map((x) => {
                                                    return (
                                                        <>
                                                            <div className="table-chat">
                                                                <div className="user-get">
                                                                    <div className="user-get-text">
                                                                        <h4>{x?.username_reporting}</h4>
                                                                        {/* <p>{props?.roomIdData.openCasePostsSinceDate}</p> */}
                                                                        <p>{dayjs.unix(x?.date?.seconds).fromNow()}</p>
                                                                    </div>
                                                                    <div className="user-get-text">
                                                                        <p className="w-fix">Text</p>
                                                                        <p className="w-fix">{x?.reason}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>

                                    <div className="picture">
                                        <div style={{
                                            position: "fixed",
                                            marginTop: "120px",
                                        }}>
                                            <div className="report-text">
                                                <p>Reported for: {props?.reason} </p>
                                            </div>
                                            <div ref={spaceRef} />

                                            <div className="chat-div" ref={scrollableRef}>
                                                {
                                                    props?.messages?.map((x) => {
                                                        return (
                                                            x.userID === props?.reportedUser.userID ? (
                                                                <>
                                                                    <div className="chat-width">
                                                                        <div className="chater">
                                                                            <p>{x.text}</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        x?.text === "Replied to a Post"
                                                                        &&
                                                                        <div className="chat-width">
                                                                            <div className="chater">
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    flexDirection: 'column',
                                                                                    justifyContent: 'center',
                                                                                    maxHeight: "300px",
                                                                                    maxWidth: "150px"
                                                                                }}>
                                                                                    <p style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'flex-start',
                                                                                        width: '100%'
                                                                                    }}>Caption: {x?.post_info?.caption}</p>
                                                                                    <div style={{
                                                                                        height: "200px",
                                                                                        width: "100px",
                                                                                        objectFit: 'contain',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center'
                                                                                    }}>
                                                                                        <img src={x?.post_info?.image_url} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="chat-width chat-width2">
                                                                        <div className="chater chater2">
                                                                            <p>{x.text}</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        x?.text === "Replied to a Post"
                                                                        &&
                                                                        <div className="chat-width chat-width2">
                                                                            <div className="chater chater2">
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    flexDirection: 'column',
                                                                                    justifyContent: 'center',
                                                                                    maxHeight: "300px",
                                                                                    maxWidth: "150px"
                                                                                }}>
                                                                                    <p style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'flex-start',
                                                                                        width: '100%'
                                                                                    }}>Caption: {x?.post_info?.caption}</p>
                                                                                    <div style={{
                                                                                        height: "200px",
                                                                                        width: "100px",
                                                                                        objectFit: 'contain',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center'
                                                                                    }}>
                                                                                        <img src={x?.post_info?.image_url} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="response">
                                                <strong style={{ fontSize: "20px" }}>Res:
                                                    {' '}
                                                    {renderModStatus(props?.mod_res, props?.mod_reason)}
                                                </strong><br />
                                                <p style={{
                                                    marginTop: "3px",
                                                    maxWidth: "200px",
                                                    whiteSpace: "normal",
                                                    wordWrap: "break-word",
                                                    overflowWrap: "break-word",
                                                }}>
                                                    Comment: {props?.mod_comment === "No Reason" ? (
                                                        <span>ø</span>
                                                    ) : (
                                                        <span>{props?.mod_comment}</span>
                                                    )}
                                                </p>
                                                <p>
                                                    Mod: {props?.mod_uid}
                                                </p>
                                                {/* <p>
                                                Date: {time.years} years {time.months} months {time.days} days
                                                {time.hours} hours {time.minutes} minutes {time.seconds} sec
                                                ago
                                            </p> */}
                                                <p>Date: {utcTime}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="description description-right">
                                        {/* <small className="time">{dayjs.unix(props?.datePost).fromNow()}</small> */}
                                        <h3>Reporting User</h3>
                                        <p>
                                            <b>Username: {props?.reportingUser?.username || ""} </b>
                                        </p>
                                        <p className="user-data-id"> userID: {props?.reportingUser?.userID} </p>

                                        <p> age: {props?.reportingUser?.age}  </p>
                                        <p> country: {props?.reportingUser?.country} </p>
                                        <p> gender: {props?.reportingUser?.gender} </p>
                                        <p> ProfileImage: <img className="img-circle" src={props?.reportingUser?.first_profile_url} alt="image not available"></img>  </p>
                                    </div>


                                    {/* {props?.controls} */}
                                </>
                            )
                        }
                    </>
                ) : (
                    <>
                        {!isPictureFullscreen && (
                            <div onClick={unselect} className="close">
                                Close
                            </div>
                        )}
                        {isloading && <p>Loading ...</p>}
                        <div className="description description-add">

                            <h3>Reported User</h3>
                            <p>
                                <b>Username: {props?.roomIdData.reportedUsername} </b>
                            </p>

                            <p className="user-data-id"> userID: {props?.roomIdData.openCase && props?.roomIdData.openCase.length && props?.roomIdData.openCase[0].uid_reported} </p>

                            <p> age: {props?.roomIdData.reportedAge} </p>
                            <p> country: {props?.roomIdData.reportedCountry} </p>
                            <p> gender: {props?.roomIdData.reportedGender} </p>
                            <p>ip: {ipStatus}</p>
                            <p>ip_threat: {ipThreat}</p>
                            <p> VerifImage: <img className="img-circle" src={reportedImg} alt="image not available"></img>  </p>

                            <p> ProfileImage: <img className="img-circle" src={props?.roomIdData.reportedProfileImage} alt="image not available"></img>  </p>

                            <div className="w-100">
                                <h4>{props?.roomIdData?.openCase?.length >= 2 && "🔴"} Open Cases ({props?.roomIdData?.openCase?.length})</h4>
                                {
                                    props?.roomIdData?.openCase?.map((x) => {
                                        return (
                                            <>
                                                <div className="table-chat">
                                                    <div className="user-get">
                                                        <div className="user-get-text">
                                                            <h4>{x?.username_reporting}</h4>
                                                            {/* <p>{props?.roomIdData.openCasePostsSinceDate}</p> */}
                                                            <p>{dayjs.unix(x?.date?.seconds).fromNow()}</p>
                                                        </div>
                                                        <div className="user-get-text">
                                                            <p className="w-fix">Text</p>
                                                            <p className="w-fix">{x?.reason}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }

                            </div>

                            <div className="w-100 mt-4">
                                <h4>Close Cases ({props?.roomIdData?.closeCase?.length})</h4>
                                {
                                    props?.roomIdData?.closeCase?.map((x) => {
                                        return (
                                            <>
                                                <div className="table-chat">
                                                    <div className="user-get">
                                                        <div className="user-get-text">
                                                            <h4>{x?.username_reporting}</h4>
                                                            {/* <p>{props?.roomIdData.openCasePostsSinceDate}</p> */}
                                                            <p>{dayjs.unix(x?.date?.seconds).fromNow()}</p>
                                                        </div>
                                                        <div className="user-get-text">
                                                            <p className="w-fix">Text</p>
                                                            <p className="w-fix">{x?.reason}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }

                            </div>
                        </div>

                        <div className="picture">
                            <div>
                                <div className="report-text">
                                    <p>Reported for: {props?.roomIdData.reason} </p>
                                </div>
                                <div ref={spaceRef} />

                                <div className="chat-div" ref={scrollableRef}>
                                    {
                                        props?.roomIdData?.chat?.map((x) => {
                                            return (
                                                x.userID === props?.roomIdData.uid_reported ? (
                                                    <div className="chat-width">
                                                        <div className="chater">
                                                            <p>{x.text}</p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="chat-width chat-width2">
                                                        <div className="chater chater2">
                                                            <p>{x.text}</p>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="description description-right">
                            {/* <small className="time">{dayjs.unix(props?.datePost).fromNow()}</small> */}
                            <h3>Reporting User</h3>
                            <p>
                                <b>Username: {props?.roomIdData?.reportingUsername} </b>
                            </p>
                            <p className="user-data-id"> userID: {props?.roomIdData.openCase && props?.roomIdData.openCase.length && props?.roomIdData.openCase[0].uid_reporting} </p>

                            <p> age: {props?.roomIdData.reportingAge} </p>
                            <p> country: {props?.roomIdData.reportingCountry} </p>
                            <p> gender: {props?.roomIdData.reportingGender} </p>
                            <p> VerifImage: <img className="img-circle" src={reportingImg} alt="image not available"></img>  </p>

                            <p> ProfileImage: {props?.roomIdData?.reportingProfileImage ? <img className="img-circle" src={props?.roomIdData.reportingProfileImage} alt="image not available"></img> : "Image not available"} </p>
                        </div>
                        {props?.controls}
                    </>
                )}

            </div>
        );
    }

    // Not active

    return (
        <div>
            <div
                className={"post " + ((isBoosted || isSafelyBoosted) && " boosted")}
                onClick={handleSelect}
            >
                {/* <img
                    src={props?.imageUrlPost}
                    alt={`${props?.username}'s profile`}
                    {...(user.developer ? { developer: "true" } : {})}
                /> */}
                {
                    props?.mod_uid ? (
                        <>
                            {
                                props?.type ? (
                                    <>
                                        <p className="p-wrap">{props?.report_counter >= 2 && "🔴"} Open({props?.report_counter})•{props?.reportedFilter ? props?.reportingUser?.userID : (props?.reportedUser.userID || "")}</p>
                                        <div className={"description "}>
                                            {isBoosted && "!!! ⚠️"}
                                            {isSafelyBoosted && "🔥 "}
                                            {props?.post_seen_mod && "🟢"}
                                            {props?.post_seen_mod && props?.postText && " • "}
                                            {props?.postText && props?.postText}
                                            {isSafelyBoosted && " 🔥"}
                                            {isBoosted && "⚠️ !!!"}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <p className="p-wrap">{props?.open_report_counter >= 2 && "🔴"} Open({props?.open_report_counter})•{props?.reportedFilter ? props?.reportingUser?.userID : (props?.reportedUser?.userID || "")}</p>
                                        <div className={"description "}>
                                            {isBoosted && "!!! ⚠️"}
                                            {isSafelyBoosted && "🔥 "}
                                            {props?.post_seen_mod && "🟢"}
                                            {props?.post_seen_mod && props?.postText && " • "}
                                            {props?.postText && props?.postText}
                                            {isSafelyBoosted && " 🔥"}
                                            {isBoosted && "⚠️ !!!"}
                                        </div>
                                    </>
                                )
                            }


                        </>
                    ) : (
                        <>
                            {
                                props?.type ? (
                                    <>
                                        <p className="p-wrap">{props?.report_counter >= 2 && "🔴"} Open({props?.report_counter})•{props?.id}</p>
                                        <div className={"description "}>
                                            {isBoosted && "!!! ⚠️"}
                                            {isSafelyBoosted && "🔥 "}
                                            {props?.post_seen_mod && "🟢"}
                                            {props?.post_seen_mod && props?.postText && " • "}
                                            {props?.postText && props?.postText}
                                            {isSafelyBoosted && " 🔥"}
                                            {isBoosted && "⚠️ !!!"}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <p className="p-wrap">{props?.open_report_counter >= 2 && "🔴"} Open({props?.open_report_counter})•{props?.uid}</p>
                                        <div className={"description "}>
                                            {isBoosted && "!!! ⚠️"}
                                            {isSafelyBoosted && "🔥 "}
                                            {props?.post_seen_mod && "🟢"}
                                            {props?.post_seen_mod && props?.postText && " • "}
                                            {props?.postText && props?.postText}
                                            {isSafelyBoosted && " 🔥"}
                                            {isBoosted && "⚠️ !!!"}
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }

                {/* <p className="p-wrap">{props?.open_report_counter >= 2 && "🔴"} Open({props?.open_report_counter})•{props?.uid}</p>
                <div className={"description "}>
                    {isBoosted && "!!! ⚠️"}
                    {isSafelyBoosted && "🔥 "}
                    {props?.post_seen_mod && "🟢"}
                    {props?.post_seen_mod && props?.postText && " • "}
                    {props?.postText && props?.postText}
                    {isSafelyBoosted && " 🔥"}
                    {isBoosted && "⚠️ !!!"}
                </div> */}

            </div>
        </div>
    );
}
