import { useSelector } from "react-redux";
import { db } from "../../../../app/firebase";
import { selectUser } from "../../../auth/userSlice";

export const fetchPosts = async (isHistory, mod_uid) => {
    try {
        let res = db.collection('age');
        if (isHistory) {
            res = res.where("waiting_moderation", "==", false);
            if(mod_uid){
                res = res.where("mod_uid", "==", mod_uid);
            }
        } else {
            res = res.where("waiting_moderation", "==", true)
        }

        if (!isHistory) {
            res = res
                .orderBy("priority", "desc")
        }
        if (isHistory) {
            res = res.orderBy("mod_date", "desc")
        } else {
            res = res.orderBy("date", "desc")
        }
        res = await res
            .limit(20)
            .get();

        let lastVisible = res?.docs[res?.docs?.length - 1];
        const postsArray = [];
        res.forEach(post => {
            postsArray.push(post.data())
        });

        return [postsArray, lastVisible]
    } catch (error) {
        console.error("error occured while fetching posts:", error)
    }
}

export const ADMIN_UID_TO_NAME = {
    "ppSfJg7w0cP97RDIKkCVv2uqnZT2": "Andrea",
    "ycHdPmvT4IRYLseSyDYwd3yl8jP2": "Lama",
    "jvuqRJ6F1iX5mKvtsXIDDUUguFr2": "Nazek",
    "1Jfo0I33czTkXl8JHVzlhf13ELG2": "Rashel",
    "njuuPRgV9EeB21ShGHBjFCBB0YB2": "Sara T.",
    "0VGc21UpTBTssL72Xvv2EqK7rhz2": "Remy",
    "8OaqIpEQQDduH4TAdnqJKuEHZUG2": "Gina",
    "TV6vQjasQ2NEAFt79YpKcoVgbD93": "Manal",
    "Ku18iqebeSgFhOjkKdePV8Rb3ew1": "Muhanad",
    "OcO8YQZDEZRmI6wf7tS9GveGpkq2": "Sara Y.",
    "OQ3qTCRQODZJ78Em4sXuHS6t6NA2": "Aya",
    "BuT86HiYu1b14bmbffvLv5w7D362": "Layale",
    "8InOiDv72waRp4Nul3aFddOS1ng2": "Clement",
    "1bm4BQiW86cz96RsEJkECHZW89E2": "Shogheir",
    "pZn6aKD7GDUVKD1JDJsJW474Azp2": "Aboudi (DEV)",
    "42qwRbaoxdXad83huD6h6h6wAi83": "Moon",
    "7KBSvNg5HuOfwY3BmUZS4WcJV2e2": "Mars",
    "cRaB1a4XnnODOdyybtLuFicrLv93": "Jupiter",
    "od2wrmeuAvUPeip05wS1wsD2D3s1": "Saturn"
}
export const loadAdmins = (setAdmins, currentAdmin) => {
    const adminsArray = [];
    db.collection("admin")
      .where("admin", "==", true)
      .get()
      .then((snapshot) => {
        snapshot.forEach((admin) => {
          const { userID } = admin.data();
          adminsArray.push({ label: ADMIN_UID_TO_NAME[userID] || userID, value: userID });
        });
        adminsArray.sort((a, b) => a.label.localeCompare(b.label));
        if (currentAdmin) {
          const currentAdminIndex = adminsArray.findIndex(admin => admin.value === currentAdmin);
          if (currentAdminIndex !== -1) {
            const [currentAdminEntry] = adminsArray.splice(currentAdminIndex, 1);
            adminsArray.unshift(currentAdminEntry);
          }
        }
        setAdmins(adminsArray);
      });
}

export const fetchNext = async (isHistory, startAfter) => {
    try {
        let res = db.collection('age');
        if (isHistory) {
            res = res.where("waiting_moderation", "==", false);
        } else {
            res = res.where("waiting_moderation", "==", true)
        }
        if (!isHistory) {
            res = res
                .orderBy("priority", "desc")
        }
        if (isHistory) {
            res = res.orderBy("mod_date", "desc")
        } else {
            res = res.orderBy("date", "desc")
        }
        res = await res
            .startAfter(startAfter)
            .limit(20)
            .get();

        let lastVisible = res?.docs[res?.docs?.length - 1];

        const postsArray = [];
        res.forEach(post => {
            postsArray.push(post?.data())
        });
        return [postsArray, lastVisible, res?.docs?.length]
    } catch (error) {
        console.error("error occured while fetching posts:", error)
    }
}

export const ageFlag = (reqAge, aiAge) => {
    let diff = (aiAge - reqAge).toFixed(1);
    if (reqAge < 18) {
        if (diff >= 1) {
            return [true, diff];
        }
    } else {
        if (diff >= 2) {
            return [true, diff];
        }
    }
    return [false, diff];
}

export const resToString = (res) => {
    let str = ""
    if (res === 1) {
        str = "Accepted"
    }
    else if (res === 2) {
        str = "Rejected"
    }
    else if (res === 3) {
        str = "Banned"
    }
    else if (res === 4) {
        str = "Support"
    }
    return str;
}

export function convertSecondsTo(seconds) {
    const referenceDate = new Date(0);
    const utcDate = new Date(referenceDate.getTime() + seconds * 1000);
    const year = utcDate.getFullYear();
    const month = padZero(utcDate.getMonth() + 1);
    const day = padZero(utcDate.getDate());
    let hours = padZero(utcDate.getHours());
    let minutes = padZero(utcDate.getMinutes());
    const sec = padZero(utcDate.getSeconds());
    let ampm = 'am';

    if (hours > 12) {
        hours -= 12;
        ampm = 'pm';
    } else if (hours === 12) {
        ampm = 'pm';
    } else if (hours === 0) {
        hours = 12;
    }

    if (minutes < 10) {
        minutes = '0' + minutes;
    }

    return `${day}/${month}/${year}`;
}

function padZero(num) {
    return num.toString().padStart(2, '0');
}