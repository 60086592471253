import { Skeleton } from "@mui/material";
import { forwardRef } from "react";
import {
  selectActivePost,
  selectDetailsLoading,
  selectShowUiLoading,
} from "../../chatSlice";
import { useSelector } from "react-redux";
import AvatarWithFallback from "./AvatarWithFallback";

const RightVertical = forwardRef(
  ({ post, onMouseEnter, onMouseLeave }, ref) => {
    const activePost = useSelector(selectActivePost);
    const detailsLoading = useSelector(selectDetailsLoading);
    const showUiLoading = useSelector(selectShowUiLoading);
    const loading = (detailsLoading && !post?.startedFetching) || showUiLoading; // because the first post will not show loading
    const {
      reportingUsername = "",
      reportingProfileImage = "",
      reportingVerifyImage = "",
      reportingAge = "",
      reportingCountry = "",
      reportingGender = "",
    } = post;
    return (
      <div
        className="right-vertical-container"
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="font-l">Reporting User</div>
        <AvatarWithFallback
          src={reportingProfileImage}
          alt="Profile Image not available"
          isSquare={true}
          style={{
            margin: 0,
          }}
        />
        <div>
          {" "}
          {loading ? (
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1.2rem",
                backgroundColor: "white",
                height: "50px",
                width: "100px",
              }}
            />
          ) : (
            <span
              style={{
                fontSize: "clamp(18px, 1.5vw, 28px)",
                fontWeight: "500",
              }}
            >
              {" "}
              {reportingUsername || ""}
            </span>
          )}
        </div>
        <div className="bold font-l">
          {loading ? (
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1rem",
                backgroundColor: "white",
                height: "50px",
                width: "150px",
              }}
            />
          ) : (
            <span
              className="no-bold font-xxl"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "23px",
              }}
            >
              {reportingGender === 0 ? "🙋🏻‍♂️" : "🙋🏻‍♀️"}{" "}
              {reportingCountry === "🇳🇬" ? "•" : `• ${reportingCountry} •`}{" "}
              {reportingAge}
              {" yo"}
            </span>
          )}
        </div>
        {!loading && (
          <AvatarWithFallback
            src={reportingVerifyImage}
            alt="Verify Image not available"
            isSquare={false}
            style={{
              margin: 0,
              borderRadius: "10px",
            }}
            height={210}
            width={130}
          />
        )}
      </div>
    );
  }
);

export default RightVertical;
