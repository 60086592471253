import { Alert, Card, CardContent, CardHeader, Snackbar } from "@mui/material";
import { useState, memo } from "react";
import { useDispatch } from "react-redux";
import { setActivePost } from "../../../chatSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default memo(function ChatCard(props) {
  const { open_report_counter, uid, idx, is_moderated, is_processing_moderation } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Card
        sx={{
          maxWidth: 200,
          minHeight: 250,
          bgcolor: "#CCCCCC",
          display: "flex",
          flexDirection: "column",
          color: is_moderated ? "green" : is_processing_moderation ? "grey" : "black",
          border: is_moderated ? "2px solid green" : is_processing_moderation ? "2px solid grey" : "none",
          borderRadius: "10px",
          pointerEvents: is_moderated || is_processing_moderation ? "none" : "auto",
          // padding: "5px"
        }}
        className="card-item"
      >
        <CardHeader
          subheader={
            <>
              {is_moderated && (
                <CheckCircleIcon style={{ marginRight: 4, color: "green" }} />
              )}
              {open_report_counter >= 2 && "🔴 "}
              {`${
                open_report_counter > 0 ? `Open(${open_report_counter}) • ` : ""
              }${uid.length > 5 ? uid.substring(0, 8) : uid}`}
            </>
          }
          subheaderTypographyProps={{
            style: {
              color: is_moderated ? "green" : is_processing_moderation ? "grey" : "black",
            },
          }}
          style={{
            height: "15%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            color: "black",
            paddingLeft: "2px",
            cursor: is_moderated ? "default" : "pointer",
          }}
          onClick={() => {
            if (!is_moderated) {
              navigator.clipboard.writeText(uid);
              setOpen(true);
            }
          }}
        />
        <CardContent
          sx={{
            flexGrow: 1,
          }}
          onClick={() => {
            if (!is_moderated) {
              dispatch(setActivePost(idx));
            }
          }}
        />
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.64)",
            color: "white",
            textAlign: "center",
            borderTop: "1px solid #ccc",
            height: "12px"
          }}
        >
        </div>
      </Card>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          UID copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
});
