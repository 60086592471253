import { useEffect, useState } from "react";
import Pagination from "../header/Pagination";
import RightDisplay from "../header/RightDisplay";
import "./header.css";
import LeftDisplay from "./LeftDisplay";
import useChats from "../../useChats";
import { changePage, clearPosts, selectActionTypeOption, selectAdminFilter, selectRange, selectReportedFilter, setLoading, setPosts, setReportedFilter } from "../../chatSlice";
import { useDispatch, useSelector } from "react-redux";

export default function HeaderHistory({id}) {
  const { fetchHistoryChats } = useChats();
  const dispatch = useDispatch();
  const reportedFilter = useSelector(selectReportedFilter);
  const actionTypeOption = useSelector(selectActionTypeOption);
  const adminFilter = useSelector(selectAdminFilter);
  const range = useSelector(selectRange);
  
  useEffect(()=>{
    dispatch(setReportedFilter(id));
  },[])

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(clearPosts());
    fetchHistoryChats(
      null,
      1,
      reportedFilter,
      adminFilter,
      range,
      actionTypeOption
    );
  }, [reportedFilter, actionTypeOption, adminFilter, range, actionTypeOption]);

  return (
    <div className="header-container-history">
      <LeftDisplay />
      <Pagination />
      <RightDisplay />
    </div>
  );
}
