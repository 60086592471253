import { Avatar, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import { selectPage } from "../../chatSlice";
import { previousPage } from "../../utils";
import useChats from "../../useChats";

export default function Pagination() {
  const { fetchNextChats } = useChats();
  const page = useSelector(selectPage);
  return (
    <div className="chat-header-pagination">
      <Button
        variant="contained"
        // startIcon={<ArrowBackIosIcon />}
        size="medium"
        style={{
          backgroundColor: "#BBBBBB",
          color: "white",
          fontWeight: "normal"
        }}
        onClick={() => {
          previousPage(page);
        }}
      >
        Back
      </Button>
      <Avatar
        sx={{ bgcolor: "white", borderRadius: "5px", color: "rgb(81, 78, 78)" }}
        variant="square"
      >
        {page}
      </Avatar>
      <Button
        variant="contained"
        // endIcon={<ArrowForwardIosIcon />}
        size="medium"
        style={{
          backgroundColor: "#BBBBBB",
          color: "white",
          fontWeight: "normal"
        }}
        onClick={fetchNextChats}
      >
        Next
      </Button>
    </div>
  );
}
