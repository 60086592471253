import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import {
  selectDetailsLoading,
  selectShowUiLoading,
  setLoading,
} from "../../chatSlice";
import { useSelector, useDispatch } from "react-redux";
import BrokenImageIcon from "@mui/icons-material/BrokenImage"; // Optional: For a fallback icon

const AvatarWithFallback = ({
  src,
  alt,
  isSquare = false,
  style,
  width,
  height,
  fallbackContent,
}) => {
  const [imageStatus, setImageStatus] = useState("loading");
  const detailsLoading = useSelector(selectDetailsLoading);
  const showUiLoading = useSelector(selectShowUiLoading);
  const globalLoading = detailsLoading || showUiLoading;

  useEffect(() => {
    if (globalLoading && src && imageStatus !== "loaded") {
      setImageStatus("loading");
    }
  }, [globalLoading, imageStatus]);

  // Reset imageStatus to 'loading' whenever src changes
  useEffect(() => {
    if (src) {
      setImageStatus("loading");
    } else {
      setImageStatus("error");
    }
  }, [src]);

  useEffect(() => {
    let timeoutRef;
    if (imageStatus === "loading") {
      timeoutRef = setTimeout(() => {
        setImageStatus("loaded");
        console.log("Loading State For More Than 1.5 Second");
      }, 1500);
    }
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, [imageStatus]);

  // Handlers for image load and error
  const handleLoad = useCallback(() => {
    setImageStatus("loaded");
  }, []);

  const handleError = useCallback(() => {
    setImageStatus("error");
  }, []);

  // Determine Skeleton variant based on isSquare prop
  const skeletonVariant = isSquare ? "rectangular" : "circular";
  const skeletonSize = isSquare ? 150 : 120;

  // Determine Avatar size based on isSquare prop
  const avatarSize =
    width && height
      ? { width, height }
      : isSquare
      ? { width: 150, height: 150 }
      : { width: 120, height: 120 };

  return (
    <>
      {imageStatus === "loading" && (
        <Skeleton
          variant={isSquare ? "square" : "circular"}
          width={avatarSize.width}
          height={avatarSize.height}
          sx={{
            ...avatarSize,
            padding: 0,
            margin: "0 auto",
            bgcolor: "white",
            fontSize: 80,
            borderRadius: isSquare ? "10px" : "50%",
            ...style,
          }}
        />
      )}

      {imageStatus === "error" && <></>}

      {imageStatus === "loaded" && (
        <Avatar
          src={src}
          alt={alt}
          onLoad={handleLoad}
          onError={handleError}
          sx={{
            ...avatarSize,
            padding: 0,
            margin: "0 auto",
            bgcolor: "#ccc",
            fontSize: 80,
            borderRadius: isSquare ? "10px" : "50%",
            ...style,
          }}
          variant={isSquare ? "square" : "circular"}
        />
      )}

      {/* Preload image to trigger onLoad and onError */}
      {src && (
        <img
          src={src}
          alt={alt}
          style={{ display: "none" }}
          onLoad={handleLoad}
          onError={handleError}
        />
      )}
    </>
  );
};

// PropTypes for type checking
AvatarWithFallback.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  isSquare: PropTypes.bool,
  style: PropTypes.object,
  fallbackContent: PropTypes.node, // Optional: Custom fallback content
};

// Default props
AvatarWithFallback.defaultProps = {
  src: "",
  isSquare: false,
  style: {},
  fallbackContent: null,
};

export default React.memo(AvatarWithFallback);
