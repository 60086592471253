import { Alert, Button, Snackbar } from "@mui/material";
import "./fullscreen.css";
import LeftVertical from "./LeftVertical";
import RightVertical from "./RightVertical";
import MiddleContainer from "./MiddleContainer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  exitFullScreen,
  selectActivePost,
  selectDetailsLoading,
  selectIsHistory,
  selectPage,
  selectPosts,
  selectPreFetchedFirstPostPages,
  selectShowUiLoading,
  setActivePost,
  setDetailsLoading,
  setShowUiLoading,
} from "../../chatSlice";
import useChats from "../../useChats";
import SearchUserPopup from "./SearchUserPopup";

export default function FullScreen({ onClose, onNext, onPrevious }) {
  const [hoveredComponent, setHoveredComponent] = useState(null);
  const [showUrgentChatBanner, setShowUrgentChatBanner] = useState(false);
  const [timer, setTimer] = useState(null);
  const leftRef = useRef(null);
  const middleRef = useRef(null);
  const rightRef = useRef(null);

  const dispatch = useDispatch();
  const { handleKeyUp, fetchChatDetails, goToNextPost } = useChats(
    hoveredComponent,
    leftRef,
    middleRef,
    rightRef,
    setShowUrgentChatBanner
  );

  const currentPage = useSelector(selectPage);
  const activePost = useSelector(selectActivePost);
  const posts = useSelector(selectPosts);
  const isHistory = useSelector(selectIsHistory);
  const showUiLoading = useSelector(selectShowUiLoading);
  const detailsLoading = useSelector(selectDetailsLoading);
  const preFetchArray = useSelector(selectPreFetchedFirstPostPages);

  const currentPost = useMemo(() => {
    if (posts[currentPage] && posts[currentPage][activePost]) {
      return posts[currentPage][activePost];
    }
    return null;
  }, [posts, currentPage, activePost]);

  useEffect(() => {
    if (detailsLoading || showUiLoading) {
      // Clear any existing timer
      if (timer) {
        clearTimeout(timer);
      }

      // Start a new timer
      const newTimer = setTimeout(() => {
        console.log("Timer triggered after delay while details are loading");
        if (detailsLoading) dispatch(setDetailsLoading(false));
        if (showUiLoading) dispatch(setShowUiLoading(false));
        console.log("showUiLoading is false 2");
      }, 2000); // Adjust the delay as needed

      setTimer(newTimer);
    }

    return () => {
      // Cleanup when activePost changes
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [detailsLoading, showUiLoading, activePost]);

  useEffect(() => {
    if (currentPost && currentPost.isModerated) {
      // dispatch(setActivePost(-1));
      if (activePost < posts[currentPage].length - 1) {
        goToNextPost();
      } else {
        dispatch(setActivePost(-1));
      }
    }
  }, [currentPost, dispatch, activePost, posts, currentPage, goToNextPost]);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [onClose, onNext, onPrevious, handleKeyUp]);

  useEffect(() => {
    const fetchData = async () => {
      if (isHistory) return;
      if (!currentPost?.reportedUserID) {
        // or any property unique to fetching details
        // First, fetch the current post's details
        if (
          (preFetchArray.includes(currentPage) && activePost === 0) ||
          detailsLoading
        ) {
          return;
        }
        console.log(
          "fetching details with startedFetching = ",
          currentPost?.startedFetching
        );
        const result = await fetchChatDetails(currentPost?.uid, activePost);
        if (!result) {
          alert("Failed to fetch chat details, please reload the page");
        }
      } else {
        if (showUiLoading) {
          setTimeout(() => {
            dispatch(setShowUiLoading(false));
            console.log("showUiLoading is true 1");
          }, 500);
        }
        console.log("detected that startedFetching is true");
      }
      if (activePost < posts[currentPage].length - 2) {
        if (!posts[currentPage][activePost + 1].startedFetching) {
          fetchChatDetails(
            posts[currentPage][activePost + 1].uid,
            activePost + 1,
            true
          );
        } else console.log("detected that startedFetching is true for + 1");
        if (!posts[currentPage][activePost + 2].startedFetching) {
          fetchChatDetails(
            posts[currentPage][activePost + 2].uid,
            activePost + 2,
            true
          );
        } else console.log("detected that startedFetching is true for + 2");
      }
    };
    fetchData();
  }, [
    activePost,
    isHistory,
    fetchChatDetails,
    posts,
    currentPage,
    currentPost,
    // showUiLoading,
  ]);

  return (
    <>
      <SearchUserPopup />

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showUrgentChatBanner}
        autoHideDuration={1500}
        onClose={() => setShowUrgentChatBanner(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Urgent Cases Done!
        </Alert>
      </Snackbar>

      <div className="fullscreen-container">
        <div className="fullscreen-header">
          <Button
            className="fullscreen-close-button"
            variant="contained"
            onClick={() => dispatch(exitFullScreen())}
          >
            Close
          </Button>
        </div>
        <div className="fullscreen-body">
          <LeftVertical
            post={currentPost}
            ref={leftRef}
            onMouseEnter={() => setHoveredComponent("left")}
            onMouseLeave={() => setHoveredComponent(null)}
          />
          {/* Middle Container */}
          <MiddleContainer
            post={currentPost}
            ref={middleRef}
            onMouseEnter={() => setHoveredComponent("middle")}
            onMouseLeave={() => setHoveredComponent(null)}
          />
          {/* RightVertical */}
          <RightVertical
            post={currentPost}
            ref={rightRef}
            onMouseEnter={() => setHoveredComponent("right")}
            onMouseLeave={() => setHoveredComponent(null)}
          />
        </div>
      </div>
    </>
  );
}
